import React from 'react'
import Menu from '../Menu';
import Footer from '../Footer';
import Auth from './../../middleware/Auth';

// import woman from './../../assets/img/woman.jpg';
import DatePicker ,{registerLocale}from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cl from "date-fns/locale/es"; // the locale you want
registerLocale("cl", cl); // register it with the name you want
import {VictoryLegend, VictoryChart, VictoryLine, VictoryAxis, VictoryTheme } from 'victory'

class Estadisticas extends React.Component{
  constructor(props){
    super(props)
    this.state={
      user: JSON.parse(sessionStorage.getItem("user")),
      rutinas: [],
      usuarios: [],
      repeticiones: [],
      show_mejor: true,
      show_peor: true,
      show_promedio:true,
      rutina: "",
      usuario: ""
    }
    this.handleChange = (e)=>{this.setState({[e.target.name]: e.target.value}, this.loadRepeticiones)}
  }

  componentDidMount(){
    this.loadRutinas();
    if (this.state.user.is_admin)
    this.loadUsuarios();
    
    this.loadRepeticiones();
  }

  loadRutinas(){
    var url = "api/rutinas"
    fetch(url,{
      method: "GET",
     headers: {
      'access-token': Auth.getToken().access_token,
      'client': Auth.getToken().client,
      'uid': Auth.getToken().uid
      }
    }).then((res)=>{
      Auth.updateToken(res.headers.get('access-token'));
      return res.json()
    }).then((res)=>{
      console.log("res loadRutinas",res)
      this.setState({rutinas: res.data})

    }).catch((error)=>{
      console.log(error)
    })
  }

  loadUsuarios(){
    var url = "api/users"
    fetch(url, {
      method: "GET",
      headers: {
        'access-token': Auth.getToken().access_token,
        'client': Auth.getToken().client,
        'uid': Auth.getToken().uid
      }
    }).then((res)=>{
      return res.json()
    }).then((res)=>{
      console.log("res load users:", res)
      this.setState({usuarios: res.data})
    }).catch((error)=>{
      console.log("error load usuarios:", error)
    })
  }

  loadRepeticiones(){
    var url = "api/lista_repeticiones"
    var data = {
      user_id: this.state.user.id
    }

    if(this.state.usuario != "")
    data.user_id = this.state.usuario

    if(this.state.rutina != "")
    data.rutina_id = this.state.rutina
    
    console.log("loadRepeticiones",data)

    fetch(url,{
      method: "POST",
      headers:{
        "Content-Type": "application/json",
        'access-token': Auth.getToken().access_token,
        'client': Auth.getToken().client,
        'uid': Auth.getToken().uid
      },
      body: JSON.stringify(data)
    }).then((res)=>{
      Auth.updateToken(res.headers.get('access-token'));
      return res.json()
    }).then((res)=>{
      console.log("res repeticiones",res)
      this.setState({repeticiones: res.data})

    }).catch((error)=>{
      console.log(error)
    })
  }

  tabla_repeticiones(){
    return(
      <div className="table px-10 pt-10 w-1/2">
      <div className="table-row text-center font-bold ">
        <div className="table-cell w-1/6 border-rojo border-solid  border-b-4"></div>
        <div className="table-cell w-1/6 border-rojo border-solid  border-b-4">Mejor</div>
        <div className="table-cell w-1/6 border-rojo border-solid  border-b-4">Peor</div>
        <div className="table-cell w-1/6 border-rojo border-solid  border-b-4">Promedio</div>
        <div className="table-cell w-1/6 border-rojo border-solid  border-b-4">Total</div>
        <div className="table-cell w-1/6 border-rojo border-solid  border-b-4">Pasos</div>
      </div>
      {
        this.state.repeticiones.map((rep)=>{
          return(
          <>
          <div className="h-3" ></div>
        <div className="table-row text-center font-bold">
          <div className="table-cell align-middle text-center">{rep.rep_count} </div>
          <div className="table-cell align-middle text-center">{(rep.mejor/1000).toFixed(2)} </div>
          <div className="table-cell align-middle text-center">{(rep.peor/1000).toFixed(2)} </div>
          <div className="table-cell align-middle text-center">{(rep.promedio/1000).toFixed(2)}</div>
          <div className="table-cell align-middle text-center">{(rep.tiempo_total/1000).toFixed(2)}</div>
          <div className="table-cell align-middle text-center">{rep.total_pasos}</div>
        </div>
          
          </>
          )
        })
      }
      </div>

  
    )
  }

  render(){     
    var mejor = this.state.repeticiones.map((rep)=>{return {x: parseFloat( rep.rep_count), y:parseFloat(rep.mejor/1000)}})
    var peor = this.state.repeticiones.map((rep)=>{return {x: parseFloat(rep.rep_count), y:parseFloat( rep.peor/1000)}})
    var promedio = this.state.repeticiones.map((rep)=>{return {x:parseFloat(rep.rep_count),y:parseFloat( rep.promedio/1000)}})
    var tick_values_eje_x = this.state.repeticiones.map((rep)=>{return (rep.rep_count+1).toString()})
    tick_values_eje_x = tick_values_eje_x.sort( (a,b)=>{ return a-b})
    var y_values = mejor.map((r)=>{return r.y}).concat(peor.map((r)=>{return r.y}).concat(promedio.map((r)=>{return r.y})) )
    // console.log("y_values",y_values)
    if(y_values.length >0){
      var max_y = Math.max( ...y_values )
      // console.log("max_y",max_y)
  
      var tick_values_eje_y = new Array(10)
      for(var i = 0; i<=12; i ++){
        tick_values_eje_y[i] = (max_y / 10) * i
      }
      // tick_values_eje_y = tick_values_eje_y.map((i)=>{return i.toFixed()})

      max_y = tick_values_eje_y[tick_values_eje_y.length - 1]
      console.log("tick_values_eje_y, maxy",tick_values_eje_y, max_y)
    }else{
      var tick_values_eje_y =[1,2,3,4,5,6,7,8,9]
      var max_y = 9
    }

    console.log("mejor pero promedio", mejor, peor, promedio)
    console.log("tick_values_eje_x",tick_values_eje_x)
    console.log("this.state", this.state)
    return(
      <div className="min-h-screen flex flex-col">
        <Menu active="estadisticas"/>
          <div className="flex-grow flex flex-col p-6">
          <div className="flex justify-between">
            <div className="font-bold text-4xl text-rojo mx-2">
              ESTADISTICAS
            </div>
            <div className="flex justify-between mx-2">
              <select name="rutina" value={this.state.rutina} onChange={this.handleChange}>
                <option value=""> RUTINA</option>
                {this.state.rutinas.map((rutina)=>{
                  return(
                    <option key={rutina.id} value={rutina.id} >
                      {rutina.nombre}
                    </option>
                  )
                })}
              </select>
              {
                this.state.user.is_admin?
                  <select name="usuario" value={this.state.usuario  } onChange={this.handleChange}>
                  <option value=""> USUARIO </option>
                    {this.state.usuarios.map((usuario)=>{
                      return(
                        <option value={usuario.id}>
                          {usuario.nombre}
                        </option>
                      )
                    })}
                  </select>
                :
                  null
              }

            </div>
          </div>

          <div className="flex justify-between">

          {
            this.tabla_repeticiones()
          }
          <div className="w-1/2">

          <VictoryChart
                  // theme={VictoryTheme.material}
                  domain={{x: [0,this.state.repeticiones.length+2], y: [0,max_y]}}
                  width={500}
                  height ={250}
                >
                  <VictoryLegend x={360} y={51}
                    orientation="vertical"
                    gutter={15}
                    style={{ border: { stroke: "black" } }}
                    colorScale={[ "green", "red","blue"]}
                    data={[
                      { name: "mejor" }, { name: "peor" }, { name: "promedio" }
                    ]}
                  />
                  <VictoryAxis 
                  dependentAxis
                  tickValues={tick_values_eje_y}
                  tickFormat={(t) => `${t.toFixed(1)} s`}
                  ></VictoryAxis>
                  <VictoryAxis
                  tickValues={tick_values_eje_x}
                  tickFormat={(t) => `${t}`}

                  // style={{tickLabels:{angle: 45 }}}
                  ></VictoryAxis>
                  {
                    this.state.show_promedio?
                    <VictoryLine 
                    data ={promedio} 
                    style={{data: {stroke: "blue"}}}
                    />
                    :
                    null
                  }
                  {
                    this.state.show_mejor?
                  <VictoryLine 
                    data ={mejor} 
                    style={{data: {stroke: "green"}}}

                    />
                    :
                    null
                  }
                  {
                    this.state.show_peor?
                    <VictoryLine 
                    data ={peor} 
                    style={{data: {stroke: "red"}}}

                    />
                    :
                    null
                  }
                </VictoryChart>
          

          </div>
          </div>
          </div>
        {/* HomePage */}
        <Footer/>
      </div>
    )
  }
}

export default Estadisticas;