import React from 'react';
import {Link} from 'react-router-dom';
import icono_cratus from './../../assets/img/icono_cratus.png';
import Auth from './../../middleware/Auth';
import facebook from './../../assets/img/facebook.png';
import youtube from './../../assets/img/youtube.png';
import instagram from './../../assets/img/instagram.png';

class LoginPage extends React.Component{
  constructor(props){
    super(props);
    this.login= this.login.bind(this);
    this.state={
      email: "",
      password: ""
    }
    this.handleChange = (e)=>this.setState({[e.target.name]: e.target.value})
    this.login = this.login.bind(this)

  }

  login(){
    var url = "api/auth/sign_in"
    // sessionStorage.setItem("userLoged", {nombre: "hans"})
    // window.location = "/home"
    var data = {
      email: this.state.email,
      password: this.state.password
    }
    console.log("data",data)
    fetch(url,{
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body:  JSON.stringify(data)
    }).then((res)=>{
      console.log("res",res)
      Auth.authenticateToken(res.headers.get('client'), res.headers.get('access-token'), res.headers.get('uid'));
      return res.json()
    })
    .then((res)=>{
      console.log(res)
      if(!res.errors){
        sessionStorage.setItem("user", JSON.stringify(res.data))
        window.location = "/rutinas"
      }else{
        console.log("error", res)
      }
      // sessionStorage.setItem("user", {name: "ariel"})
      // window.location = "/rutinas"
    }).catch((error)=> console.log(error))
  }
  render(){
    return( 
    <div className="w-screen h-screen flex bg-degradado">
    <div className="  page_content m-2 sm:m-16 w-full flex shadow-lg">
      <div className="w-full sm:w-2/3 flex flex-col p-5 lg:p-12 bg-white relative ">
        <div className="flex flex-col justify-around h-full">
        <div>
        <h1 className="text-center  text-rojo text-4xl font-bold">INGRESO USUARIO</h1>
        </div>
        <div className="mx-8">
        <label className="font-bold text-2xl">Usuario</label>
        <input onChange={this.handleChange} value={this.state.email} name="email"
        className=" border-solid border-rojo border-b-2 w-full focus:bg-gray-300" type="email" placeholder="EMAIL@EMAIL.COM"></input>         
        </div>
        <div className="mx-8">
        <label className="font-bold text-2xl">Clave</label>
        <input 
        onChange={this.handleChange} value={this.state.password} name="password"
        className=" border-solid border-rojo border-b-2 w-full focus:bg-gray-300" type="password" placeholder="***********"></input> 
        
        </div>

        <div className="text-center w-full">
        <button className="text-white px-8 font-bold py-1 m-2 rounded-full text-xl bg-rojo hover:shadow-lg" onClick={this.login}>
        INGRESAR
        </button> <br/>
        </div>
        {/* <span className="text-xl ml-10 mb-5 text-lg lg:absolute lg:bottom-0 lg:left-0">¿No Tienes una Cuenta? 
        <Link  className="px-3 font-bold text-rojo" to="/register">REGISTRAR</Link>
        </span>
        <span className="text-xl mr-10 mb-5  text-lg lg:absolute lg:bottom-0 lg:right-0">Volver a la página  
        <Link  className="px-3 font-bold text-rojo" to="/home">INICIO</Link>

        </span> */}
        </div>
        <div>
        <span className="ml-10 mb-4 hidden sm:inline text-sm sm:absolute sm:bottom-0 sm:left-0">
        ¿No tienes una Cuenta? 
        <Link  className="px-3 font-bold text-rojo" to="/register">REGISTRAR</Link>
        </span>
        <span className="mr-10 mb-4 hidden sm:inline text-sm sm:absolute sm:bottom-0 sm:right-0">
        Volver a la página  
        <Link  className="px-3 font-bold text-rojo" to="/home">INICIO</Link>
        </span>
        {/* celulares */}
        <Link  className="ml-10 mb-4 absolute bottom-0 left-0 sm:hidden px-3 font-bold text-rojo" to="/login">LOGIN</Link>
        <Link  className="mr-10 mb-4 absolute bottom-0 right-0 sm:hidden px-3 font-bold text-rojo" to="/home">INICIO</Link>

        </div>
      </div>
      <div className=" w-0 invisible sm:visible sm:w-1/3 bg-negro-icono-cratus flex relative">
        <div className="absolute top-0 right-0 flex">

        <div className="m-3 w-8 h-8">
            <a href="https://www.facebook.com/Cratus.chile">
              <img  src={facebook} alt="icono_cratus"></img>
            </a>
          </div>

          <div className="m-3 w-8 h-8">
            <a href="https://www.instagram.com/cratus_sport/">
              <img  src={instagram} alt="icono_cratus"></img>
            </a>
          </div>

          <div className="m-3 w-8 h-8">
            <a href="youtube.com/channel/UCeMcc57l-wrs0DwD3Dhva2A/featured?view_as=subscriber">
              <img  src={youtube} alt="icono_cratus"></img>
            </a>
          </div>

        </div>
        <div className="m-auto">
          <img src={icono_cratus} alt="logo-conversemos"></img>
        </div>
        {/* <div className="mb-4 absolute bottom-0 w-full text-center">
          <span className="lg:text-sm  text-gray-500">UN PRODUCTO DE</span>
          <h1 className="lg:text-2xl font-bold text-gray-500">IDEAS MACHINE</h1>
        </div> */}

      </div>
    </div>
    </div>
     ) 
  }
}

export default LoginPage;