import React from 'react';
import {BrowserRouter as Router, Route, Redirect} from 'react-router-dom';
import LoginPage from './components/pages/LoginPage';
import RegisterPage from './components/pages/RegisterPage';
import HomePage from './components/pages/HomePage';
import Perfil from './components/pages/Perfil';
import RutinasPage from './components/pages/RutinasPage';
import Estadisticas from './components/pages/Estadisticas';
import Condiciones from './components/pages/Condiciones';
import Contacto from './components/pages/Contacto';
import Politicas from './components/pages/Politicas';
import Usuarios from './components/pages/Usuarios';
import PreguntasFrecuentes from './components/pages/PreguntasFrecuentes';
import ChangePassword from './components/pages/ChangePassword';


const Child = (props) =>{
  if( sessionStorage.getItem("user" )!= null) {
    if(props.match.params.id === 'rutinas') return <RutinasPage {...props}/>
    if(props.match.params.id === 'estadisticas') return <Estadisticas {...props}/>
    if(props.match.params.id === 'usuarios') return <Usuarios {...props}/>
    if(props.match.params.id === 'condiciones') return <Condiciones {...props}/>
    if(props.match.params.id === 'contacto') return <Contacto {...props}/>
    if(props.match.params.id === 'politicas_y_privacidad') return <Politicas {...props}/>
    if(props.match.params.id === 'faq') return <PreguntasFrecuentes {...props}/>
    if(props.match.params.id === 'perfil') return <Perfil {...props}/>
    
    else return <Redirect to="/rutinas" />
 
  }
  else {
    if(props.match.params.id === 'login') return <LoginPage {...props}/>
    if(props.match.params.id === 'register') return <RegisterPage {...props}/>
    if(props.match.params.id === 'home') return <HomePage {...props}/>
    if(props.match.params.id === 'condiciones') return <Condiciones {...props}/>
    if(props.match.params.id === 'contacto') return <Contacto {...props}/>
    if(props.match.params.id === 'politicas_y_privacidad') return <Politicas {...props}/>
    if(props.match.params.id === 'faq') return <PreguntasFrecuentes {...props}/>
    if(props.match.params.id === 'change_password') return <ChangePassword {...props}/>
    else return <Redirect to="/login" />
   
  }
}

function App() {
  return (

      <Router>
        <Route exact path='/:id' render={Child}/>
        <Route exact path='/' render={Child}/>
      </Router>

  );
}

export default App;
