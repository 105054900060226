import React from 'react';
import {Link} from 'react-router-dom';
import icono_cratus from './../../assets/img/icono_cratus.png';
class LoginPage extends React.Component{
  constructor(props){
    super(props);
  }

  render(){
    return( 
    <div className="w-screen h-screen flex bg-degradado">
    <div className="  page_content m-2 sm:m-16 w-full flex shadow-lg">
      <div className="w-full sm:w-2/3 flex flex-col p-5 lg:p-12 bg-white relative ">
        <div className="flex flex-col justify-between h-full">
        <div>
        <h1 className="text-center  text-rojo text-4xl font-bold ">CONTACTO</h1>
        </div>
        <div className="flex justify-between">
          <div className="w-full mr-4">
          <label className="font-bold text-lg">Nombre</label> 
          <input className=" border-solid border-rojo border-b-2 w-full focus:bg-gray-300" type="text" placeholder=""></input>  
          
          </div>
          <div className="w-full ml-4">
          <label className="font-bold text-lg">Apellido</label> 
          <input className=" border-solid border-rojo border-b-2 w-full focus:bg-gray-300" type="text" placeholder=""></input>  
          
          </div>
        </div>
        <div className="flex justify-between">

        <div className="w-full mr-4">
        <label className="font-bold text-lg">E-mail</label> 
        <input className=" border-solid border-rojo border-b-2 w-full focus:bg-gray-300" type="text" placeholder=""></input>  
        
        </div>
        <div className="w-full ml-4">
        <label className="font-bold text-lg">Telefono</label> 
        <input className=" border-solid border-rojo border-b-2 w-full focus:bg-gray-300" type="text" placeholder=""></input>  
        
        </div>
        </div>
        <div>
        <label className="font-bold text-lg">Mensaje</label> 
        <textarea className=" resize-none h-32 overflow-y-scroll border-solid border-rojo border-2 w-full focus:bg-gray-300"  ></textarea>  
        
        </div>

        <div className="text-center w-full">
        <button className="text-white px-8 font-bold py-1 my-1 rounded-full text-xl bg-rojo hover:shadow-lg">
        Enviar
        </button> <br/>
        </div>
        {/* <span className="text-xl ml-10 mb-5 text-lg lg:absolute lg:bottom-0 lg:left-0">¿No Tienes una Cuenta? 
        <Link  className="px-3 font-bold text-rojo" to="/register">REGISTRAR</Link>
        </span>
        <span className="text-xl mr-10 mb-5  text-lg lg:absolute lg:bottom-0 lg:right-0">Volver a la página  
        <Link  className="px-3 font-bold text-rojo" to="/home">INICIO</Link>

        </span> */}
        </div>
        <div>
        <span className="ml-10 mb-4 hidden sm:inline text-sm sm:absolute sm:bottom-0 sm:left-0">
        ¿No tienes una Cuenta? 
        <Link  className="px-3 font-bold text-rojo" to="/register">REGISTRAR</Link>
        </span>
        <span className="mr-10 mb-4 hidden sm:inline text-sm sm:absolute sm:bottom-0 sm:right-0">
        Volver a la página  
        <Link  className="px-3 font-bold text-rojo" to="/home">INICIO</Link>
        </span>
        {/* celulares */}
        <Link  className="ml-10 mb-4 absolute bottom-0 left-0 sm:hidden px-3 font-bold text-rojo" to="/login">LOGIN</Link>
        <Link  className="mr-10 mb-4 absolute bottom-0 right-0 sm:hidden px-3 font-bold text-rojo" to="/home">INICIO</Link>

        </div>
      </div>
      <div className=" w-0 invisible sm:visible sm:w-1/3 bg-negro-icono-cratus flex relative">
        <div className="absolute top-0 right-0 flex">
        {/* <FontAwesomeIcon icon="fa-facebook-f" />
        <FontAwesomeIcon icon="fa-instagram" />
        <FontAwesomeIcon icon="fa-twitter" /> */}
          <div className="m-3 rounded-full bg-white text-black w-8 h-8 py-1 px-2">
            <i className="fab fa-facebook-f"></i>
          </div>

          <div className="m-3 rounded-full bg-white text-black w-8 h-8 py-1 px-2">
            <i className="fab fa-instagram"></i>
          </div>

          <div className="m-3 rounded-full bg-white text-black w-8 h-8 py-1 px-2">
            <i className="fab fa-twitter"></i>
          </div>

        </div>
        <div className="m-auto">
          <img src={icono_cratus} alt="logo-conversemos"></img>
        </div>
        <div className="mb-4 absolute bottom-0 w-full text-center text-white">
          <div> <i className="fas fa-phone mx-2"></i>	+56 9 1234 5678               </div>
          <div> <i className="far fa-envelope mx-2"></i>contacto<br className="inline lg:hidden"/>@cratus.cl </div>
        </div>

      </div>
    </div>
    </div>
     ) 
  }
}

export default LoginPage;