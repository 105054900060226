import React from 'react'
import Menu from '../Menu';
import Footer from '../Footer';
import Auth from './../../middleware/Auth';
import SweetAlert from 'react-bootstrap-sweetalert';

class Usuarios extends React.Component{
  constructor(props){
    super(props)
    this.state={
      alerta: null,
      user: JSON.parse(sessionStorage.getItem("user")),
      usuarios: [],
      new_user_form_open: false,
      nombre: "",
      apellidos: "",
      rut: "",
      sexo: "",
      nacionalidad: "",
      pais_residencia: "",
      region_residencia: "",
      altura: "",
      peso: "",
      telefono: "",
      fecha_nacimiento: "",
      email: ""
    }
    this.setAlert = (alerta) => this.setState({alerta: alerta})
    this.handleChange = (e)=>{this.setState({[e.target.name]: e.target.value})}
    this.registrar = this.registrar.bind(this)
  }

  componentDidMount(){
    if (this.state.user.is_admin)
    this.loadUsuarios();
  }


  loadUsuarios(){
    var url = "api/users"
    fetch(url, {
      method: "GET",
      headers: {
        'access-token': Auth.getToken().access_token,
        'client': Auth.getToken().client,
        'uid': Auth.getToken().uid
      }
    }).then((res)=>{
      return res.json()
    }).then((res)=>{
      console.log("res load users:", res)
      this.setState({usuarios: res.data})
    }).catch((error)=>{
      console.log("error load usuarios:", error)
    })
  }


  tabla_usuarios(){
    return(
      <div className="table px-10 pt-10 w-full">
      <div className="table-row text-center font-bold ">
        <div className="table-cell w-1/6 border-rojo border-solid  border-b-4">Nombre</div>
        <div className="table-cell w-1/6 border-rojo border-solid  border-b-4">Peso</div>
        <div className="table-cell w-1/6 border-rojo border-solid  border-b-4">Altura</div>
        <div className="table-cell w-1/6 border-rojo border-solid  border-b-4">Edad</div>
        <div className="table-cell w-1/6 border-rojo border-solid  border-b-4">Mail</div>
        <div className="table-cell w-1/6 border-rojo border-solid  border-b-4">Telefono</div>
      </div>
      {
        this.state.usuarios.map((user)=>{
          var edad = new Date().getFullYear() - (new Date(user.fecha_nacimiento)).getFullYear()  
          return(
          <>
          <div className="h-3" ></div>
        <div className="table-row text-center font-bold">
          <div className="table-cell align-middle text-center">{user.nombre+" "+user.apellidos} </div>
          <div className="table-cell align-middle text-center">{user.peso} </div>
          <div className="table-cell align-middle text-center">{user.altura} </div>
          <div className="table-cell align-middle text-center">{edad}</div>
          <div className="table-cell align-middle text-center">{user.email}</div>
          <div className="table-cell align-middle text-center">{user.telefono}</div>
        </div>
          
          </>
          )
        })
      }
      </div>

  
    )
  }

  new_user_form(){
    return(
      <div className="flex ">
        <div className="w-1/4 mx-2">
          <input name="nombre" value={this.state.nombre} onChange={this.handleChange} className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="text" placeholder="Nombre"></input> 
          <input name="apellidos" value={this.state.apellidos} onChange={this.handleChange} className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="text" placeholder="Apellidos"></input> 
          <select name="sexo" value={this.state.sexo} onChange={this.handleChange} className=" py-1 px-1 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" >
            <option value="">Sexo</option>
            <option value="M">Masculino</option>
            <option value="F">Femenino</option>
          </select> 
 </div>
        <div className="w-1/4 mx-2">
          <input name="rut" value={this.state.rut} onChange={this.handleChange} placeholder="Rut" className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="text"></input>         
          <input name="telefono" value={this.state.telefono} onChange={this.handleChange} placeholder="Teléfono" className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="text"></input>         
          <input name="email" value={this.state.email} onChange={this.handleChange} placeholder="Email" className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="text"></input> 
        </div>
        <div className="w-1/4 mx-2">
        <input name="nacionalidad" value={this.state.nacionalidad} onChange={this.handleChange} placeholder="Nacionalidad" className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="text"></input>         
          <input name="pais_residencia" value={this.state.pais_residencia} onChange={this.handleChange} placeholder="País residencia" className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="text"></input>         
          <input name="region_residencia" value={this.state.region_residencia} onChange={this.handleChange} placeholder="Región residencia" className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="text"></input>         
       
        </div>
        <div className="w-1/4 mx-2">
        <input name="altura" value={this.state.altura} onChange={this.handleChange} placeholder="Altura" className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="number" step="any"></input> 
          <input name="peso" value={this.state.peso} onChange={this.handleChange} placeholder="Peso" className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="number" step="any"></input> 
          <input name="fecha_nacimiento" value={this.state.fecha_nacimiento} onChange={this.handleChange} placeholder="Fecha de nacimiento" className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="text" onFocus={(e)=> e.target.type = "date" }></input> 

        </div>
      </div>

    )
  }

  registrar(){
    this.setAlert(
      <SweetAlert info title="Cargando..." onConfirm={console.log("...")} showConfirm={false}></SweetAlert>
    )
    var url = "api/auth"
    var data = {
      nombre: this.state.nombre, 
      apellidos: this.state.apellidos, 
      rut: this.state.rut, 
      sexo: this.state.sexo, 
      nacionalidad: this.state.nacionalidad, 
      pais_residencia: this.state.pais_residencia, 
      region_residencia: this.state.region_residencia, 
      altura: this.state.altura, 
      peso: this.state.peso, 
      telefono: this.state.telefono, 
      fecha_nacimiento: this.state.fecha_nacimiento, 
      email: this.state.email, 
      password:"cratus123", 
      password_confirmation: "cratus123",
      entrenador_id: this.state.user.id,
      is_admin: false
    }
    console.log("registro:", url, data)
    fetch(url,{
      method: "POST",
      headers:{
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    }).then((res)=>{ return res.json()})
    .then((res)=>{
      console.log("res", res)
      this.loadUsuarios()
      

      console.log("res", res)
      if(res.status == "success"){
        
        this.setAlert(
          <SweetAlert title="OK!" success onConfirm={()=> this.setState({new_user_form_open: false, alerta: null})}>
            Registro exitoso!
          </SweetAlert>
        )
      }else{
        this.setAlert(
          <SweetAlert title="Error!" warning onConfirm={()=> this.setAlert(null)}>
            {
              res.errors.full_messages.join(". ")
            }
          </SweetAlert>
        )
      }



    })
  }

  render(){ 
    return(
      <div className="min-h-screen flex flex-col">
        {this.state.alerta}
        <Menu active="usuarios"/>
          <div className="flex-grow flex flex-col p-6">
          <div className="flex justify-between">
            <div className="font-bold text-4xl text-rojo mx-2">
              {
                this.state.new_user_form_open?
                "NUEVO USUARIO"
                :
                "USUARIOS"
              }
              
            </div>
            <div className="flex mx-2">
              {
                this.state.new_user_form_open?
                <>
                <button className="mx-2 italic bg-rojo text-2xl text-white font-bold py-1 px-2"
                onClick={()=>this.setState({new_user_form_open: false})}
                > 
                CANCELAR
                </button>
                <button className="mx-2 italic bg-rojo text-2xl text-white font-bold py-1 px-2"
                onClick={this.registrar}
                > 
                CREAR
                </button>
                </>
                :
                <button className="italic bg-rojo text-2xl text-white font-bold py-1 px-2"
                onClick={()=>this.setState({new_user_form_open: true})}
                > 
                CREAR USUARIO
                </button>
              }


            </div>
          </div>
          {
            this.state.new_user_form_open?
              this.new_user_form()
            :
            null
          }

          <div className="flex justify-between">

          {
            this.tabla_usuarios()
          }
          </div>
          </div>
        {/* HomePage */}
        <Footer/>
      </div>
    )
  }
}

export default Usuarios;