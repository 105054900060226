import React from 'react';
import {Link} from 'react-router-dom';
import icono_cratus from './../../assets/img/icono_cratus.png';
import facebook from './../../assets/img/facebook.png';
import youtube from './../../assets/img/youtube.png';
import instagram from './../../assets/img/instagram.png';
import SweetAlert from 'react-bootstrap-sweetalert';
class RegisterPage extends React.Component{
  constructor(props){
    super(props);
    this.state={
      alerta: null,
    step: 1,
    regiones: [
            {
                "nombre": "Arica y Parinacota",
                "comunas": ["Arica", "Camarones", "Putre", "General Lagos"]
            },
            {
                "nombre": "Tarapacá",
                "comunas": ["Iquique", "Alto Hospicio", "Pozo Almonte", "Camiña", "Colchane", "Huara", "Pica"]
            },
            {
                "nombre": "Antofagasta",
                "comunas": ["Antofagasta", "Mejillones", "Sierra Gorda", "Taltal", "Calama", "Ollagüe", "San Pedro de Atacama", "Tocopilla", "María Elena"]
            },
            {
                "nombre": "Atacama",
                "comunas": ["Copiapó", "Caldera", "Tierra Amarilla", "Chañaral", "Diego de Almagro", "Vallenar", "Alto del Carmen", "Freirina", "Huasco"]
            },
            {
                "nombre": "Coquimbo",
                "comunas": ["La Serena", "Coquimbo", "Andacollo", "La Higuera", "Paiguano", "Vicuña", "Illapel", "Canela", "Los Vilos", "Salamanca", "Ovalle", "Combarbalá", "Monte Patria", "Punitaqui", "Río Hurtado"]
            },
            {
                "nombre": "Valparaíso",
                "comunas": ["Valparaíso", "Casablanca", "Concón", "Juan Fernández", "Puchuncaví", "Quintero", "Viña del Mar", "Isla de Pascua", "Los Andes", "Calle Larga", "Rinconada", "San Esteban", "La Ligua", "Cabildo", "Papudo", "Petorca", "Zapallar", "Quillota", "Calera", "Hijuelas", "La Cruz", "Nogales", "San Antonio", "Algarrobo", "Cartagena", "El Quisco", "El Tabo", "Santo Domingo", "San Felipe", "Catemu", "Llaillay", "Panquehue", "Putaendo", "Santa María", "Quilpué", "Limache", "Olmué", "Villa Alemana"]
            },
            {
                "nombre": "Región del Libertador Gral. Bernardo O’Higgins",
                "comunas": ["Rancagua", "Codegua", "Coinco", "Coltauco", "Doñihue", "Graneros", "Las Cabras", "Machalí", "Malloa", "Mostazal", "Olivar", "Peumo", "Pichidegua", "Quinta de Tilcoco", "Rengo", "Requínoa", "San Vicente", "Pichilemu", "La Estrella", "Litueche", "Marchihue", "Navidad", "Paredones", "San Fernando", "Chépica", "Chimbarongo", "Lolol", "Nancagua", "Palmilla", "Peralillo", "Placilla", "Pumanque", "Santa Cruz"]
            },
            {
                "nombre": "Región del Maule",
                "comunas": ["Talca", "Constítución", "Curepto", "Empedrado", "Maule", "Pelarco", "Pencahue", "Río Claro", "San Clemente", "San Rafael", "Cauquenes", "Chanco", "Pelluhue", "Curicó", "Hualañé", "Licantén", "Molina", "Rauco", "Romeral", "Sagrada Familia", "Teno", "Vichuquén", "Linares", "Colbún", "Longaví", "Parral", "Retíro", "San Javier", "Villa Alegre", "Yerbas Buenas"]
            },
            {
                "nombre": "Región del Biobío",
                "comunas": ["Concepción", "Coronel", "Chiguayante", "Florida", "Hualqui", "Lota", "Penco", "San Pedro de la Paz", "Santa Juana", "Talcahuano", "Tomé", "Hualpén", "Lebu", "Arauco", "Cañete", "Contulmo", "Curanilahue", "Los Álamos", "Tirúa", "Los Ángeles", "Antuco", "Cabrero", "Laja", "Mulchén", "Nacimiento", "Negrete", "Quilaco", "Quilleco", "San Rosendo", "Santa Bárbara", "Tucapel", "Yumbel", "Alto Biobío", "Chillán", "Bulnes", "Cobquecura", "Coelemu", "Coihueco", "Chillán Viejo", "El Carmen", "Ninhue", "Ñiquén", "Pemuco", "Pinto", "Portezuelo", "Quillón", "Quirihue", "Ránquil", "San Carlos", "San Fabián", "San Ignacio", "San Nicolás", "Treguaco", "Yungay"]
            },
            {
                "nombre": "Región de la Araucanía",
                "comunas": ["Temuco", "Carahue", "Cunco", "Curarrehue", "Freire", "Galvarino", "Gorbea", "Lautaro", "Loncoche", "Melipeuco", "Nueva Imperial", "Padre las Casas", "Perquenco", "Pitrufquén", "Pucón", "Saavedra", "Teodoro Schmidt", "Toltén", "Vilcún", "Villarrica", "Cholchol", "Angol", "Collipulli", "Curacautín", "Ercilla", "Lonquimay", "Los Sauces", "Lumaco", "Purén", "Renaico", "Traiguén", "Victoria", ]
            },
            {
                "nombre": "Región de Los Ríos",
                "comunas": ["Valdivia", "Corral", "Lanco", "Los Lagos", "Máfil", "Mariquina", "Paillaco", "Panguipulli", "La Unión", "Futrono", "Lago Ranco", "Río Bueno"]
            },
            {
                "nombre": "Región de Los Lagos",
                "comunas": ["Puerto Montt", "Calbuco", "Cochamó", "Fresia", "Frutíllar", "Los Muermos", "Llanquihue", "Maullín", "Puerto Varas", "Castro", "Ancud", "Chonchi", "Curaco de Vélez", "Dalcahue", "Puqueldón", "Queilén", "Quellón", "Quemchi", "Quinchao", "Osorno", "Puerto Octay", "Purranque", "Puyehue", "Río Negro", "San Juan de la Costa", "San Pablo", "Chaitén", "Futaleufú", "Hualaihué", "Palena"]
            },
            {
                "nombre": "Región Aisén del Gral. Carlos Ibáñez del Campo",
                "comunas": ["Coihaique", "Lago Verde", "Aisén", "Cisnes", "Guaitecas", "Cochrane", "O’Higgins", "Tortel", "Chile Chico", "Río Ibáñez"]
            },
            {
                "nombre": "Región de Magallanes y de la Antártíca Chilena",
                "comunas": ["Punta Arenas", "Laguna Blanca", "Río Verde", "San Gregorio", "Cabo de Hornos (Ex Navarino)", "Antártíca", "Porvenir", "Primavera", "Timaukel", "Natales", "Torres del Paine"]
            },
            {
                "nombre": "Región Metropolitana de Santiago",
                "comunas": ["Cerrillos", "Cerro Navia", "Conchalí", "El Bosque", "Estación Central", "Huechuraba", "Independencia", "La Cisterna", "La Florida", "La Granja", "La Pintana", "La Reina", "Las Condes", "Lo Barnechea", "Lo Espejo", "Lo Prado", "Macul", "Maipú", "Ñuñoa", "Pedro Aguirre Cerda", "Peñalolén", "Providencia", "Pudahuel", "Quilicura", "Quinta Normal", "Recoleta", "Renca", "San Joaquín", "San Miguel", "San Ramón", "Vitacura", "Puente Alto", "Pirque", "San José de Maipo", "Colina", "Lampa", "Tiltíl", "San Bernardo", "Buin", "Calera de Tango", "Paine", "Melipilla", "Alhué", "Curacaví", "María Pinto", "San Pedro", "Talagante", "El Monte", "Isla de Maipo", "Padre Hurtado", "Peñaflor"]
            }],
             nombre: "",
             apellidos: "",
             rut: "",
             sexo: "",
             nacionalidad: "",
             pais_residencia: "",
             region_residencia: "",
             altura: "",
             peso: "",
             telefono: "",
             fecha_nacimiento: "",
             email: "",
             password: "",
             password_confirmation: "",
    }
    this.setAlert = (alerta) => this.setState({alerta: alerta})
    this.handleChange = (e)=>this.setState({[e.target.name]: e.target.value})
    this.registrar = this.registrar.bind(this)
  }

  registrar(){
    this.setAlert(
      <SweetAlert info title="Cargando..." onConfirm={console.log("...")} showConfirm={false}></SweetAlert>
    )
    var url = "api/auth"
    var data = {
      nombre: this.state.nombre, 
      apellidos: this.state.apellidos, 
      rut: this.state.rut, 
      sexo: this.state.sexo, 
      nacionalidad: this.state.nacionalidad, 
      pais_residencia: this.state.pais_residencia, 
      region_residencia: this.state.region_residencia, 
      altura: this.state.altura, 
      peso: this.state.peso, 
      telefono: this.state.telefono, 
      fecha_nacimiento: this.state.fecha_nacimiento, 
      email: this.state.email, 
      password: this.state.password, 
      password_confirmation: this.state.password_confirmation,
      is_admin: true
    }
    console.log("registro:", url, data)
    fetch(url,{
      method: "POST",
      headers:{
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    }).then((res)=>{ return res.json()})
    .then((res)=>{
      console.log("res", res)
      if(res.status == "success"){
        
        this.setAlert(
          <SweetAlert title="OK!" success onConfirm={()=> window.location = "/login"}>
            Registro exitoso!
          </SweetAlert>
        )
      }else{
        this.setAlert(
          <SweetAlert title="Error!" warning onConfirm={()=> this.setAlert(null)}>
            {
              res.errors.full_messages.join("\n")
            }
          </SweetAlert>
        )
      }
    })
  }
  render(){
    let bg_point_1 = this.state.step == 1 ? "bg-rojo": "bg-gray-400"
    let bg_point_2 = this.state.step == 2 ? "bg-rojo": "bg-gray-400"
    return( 
    <div className="w-screen h-screen flex bg-degradado ">
      {this.state.alerta}
      <div className="  page_content m-2 sm:m-16 w-full flex shadow-lg">
      <div className=" w-full sm:w-2/3 p-5 lg:p-12 relative flex flex-col justify-around bg-white">
        {/* <div className="flex">
        <div className="w-full "> */}
        <h1 className="text-center  text-rojo text-4xl font-bold mx-10">REGISTRO</h1>
        {
          this.state.step==1?
          <>
        <input name="nombre" value={this.state.nombre} onChange={this.handleChange} className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="text" placeholder="Nombre"
          style={this.state.nombre==="" ? {borderColor: "red"}:{}}
        ></input> 
        <input name="apellidos" value={this.state.apellidos} onChange={this.handleChange} className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="text" placeholder="Apellidos"
          style={this.state.apellidos==="" ? {borderColor: "red"}:{}}
        ></input> 
        <input name="rut" value={this.state.rut} onChange={this.handleChange} placeholder="Rut" className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="text"
          style={this.state.rut==="" ? {borderColor: "red"}:{}}
        ></input>         
        <select name="sexo" value={this.state.sexo} onChange={this.handleChange} className=" py-1 px-1 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" 
          style={this.state.sexo==="" ? {borderColor: "red"}:{}}
        >
          <option value="">Sexo</option>
          <option value="M">Masculino</option>
          <option value="F">Femenino</option>
        </select> 
        <input name="nacionalidad" value={this.state.nacionalidad} onChange={this.handleChange} placeholder="Nacionalidad" className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="text"
          style={this.state.nacionalidad==="" ? {borderColor: "red"}:{}}
        ></input>         

        <input name="pais_residencia" value={this.state.pais_residencia} onChange={this.handleChange} placeholder="País residencia" className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="text"
          style={this.state.pais_residencia==="" ? {borderColor: "red"}:{}}
        ></input>         
        <input name="region_residencia" value={this.state.region_residencia} onChange={this.handleChange} placeholder="Región residencia" className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="text"
          style={this.state.region_residencia==="" ? {borderColor: "red"}:{}}
        ></input>         

        {/* <select name="pais" value={this.state.pais} onChange={this.handleChange} className=" py-1 px-1 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="select" placeholder="Nacionalidad">
          <option value ="">Pais</option>
          <option value ="chile">Chile</option>
          <option value ="otro">Otro</option>
        </select> 
        <select name="region" value={this.state.region} onChange={this.handleChange} className=" py-1 px-1 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1   focus:bg-gray-300" type="select" placeholder="Region">
        <option value ="">Region</option>
          {this.state.regiones.map((region)=>
          <option value ={region.nombre}>{region.nombre}</option>
            
            )}
        </select>  */}
        </>
        :
        <>
        <input name="telefono" value={this.state.telefono} onChange={this.handleChange} placeholder="Teléfono" className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="text"
          style={this.state.telefono==="" ? {borderColor: "red"}:{}}
          ></input>         
        <input name="altura" value={this.state.altura} onChange={this.handleChange} placeholder="Altura" className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="text"
          style={this.state.altura==="" ? {borderColor: "red"}:{}}
          ></input> 
        <input name="peso" value={this.state.peso} onChange={this.handleChange} placeholder="Peso" className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="text"
          style={this.state.peso==="" ? {borderColor: "red"}:{}}
          ></input> 
        <input name="fecha_nacimiento" value={this.state.fecha_nacimiento} onChange={this.handleChange} placeholder="Fecha de nacimiento" className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="text" onFocus={(e)=> e.target.type = "date" }
          style={this.state.fecha_nacimiento==="" ? {borderColor: "red"}:{}}
          ></input> 
        <input name="email" value={this.state.email} onChange={this.handleChange} placeholder="Email" className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="text"
          style={this.state.email==="" ? {borderColor: "red"}:{}}
          ></input> 
        <input name="password" value={this.state.password} onChange={this.handleChange} placeholder="Clave" className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="password" 
          style={this.state.password==="" ||this.state.password_confirmation != this.state.password ? {borderColor: "red"}:{}}
          ></input> 
        <input name="password_confirmation" value={this.state.password_confirmation} onChange={this.handleChange} placeholder="Repetir clave" className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="password" 
          style={this.state.password_confirmation==="" ||this.state.password_confirmation != this.state.password ? {borderColor: "red"}:{}}
          ></input> 

        </>
        }

          <div className="flex justify-center">
            <div 
            onClick = {()=>this.setState({step:1})}
            className={"rounded-full mx-2 h-4 w-4 cursor-pointer " + bg_point_1}></div>
            <div 
             onClick = {()=>this.setState({step:2})}
              className={"rounded-full mx-2 h-4 w-4 cursor-pointer "+ bg_point_2}></div>
          </div>
        {/* </div>

        </div> */}

        <div className="flex">
        <div className="text-center w-full">

        {
          this.state.step == 1?
            <button onClick={()=>this.setState({step:2})}  className="text-white px-8 font-bold py-1 my-2 rounded-full text-xl bg-rojo hover:shadow-lg" >
              SIGUIENTE
            </button> 
            :
            <>
            <button   className=" text-white px-8 font-bold py-1 my-2 rounded-full text-lg bg-rojo hover:shadow-lg"
            disabled={
              this.state.nombre == "" ||
              this.state.apellidos == "" ||
              this.state.rut == "" ||
              this.state.sexo == "" ||
              this.state.nacionalidad == "" ||
              this.state.pais_residencia == "" ||
              this.state.region_residencia == "" ||
              this.state.altura == "" ||
              this.state.peso == "" ||
              this.state.telefono == "" ||
              this.state.fecha_nacimiento == "" ||
              this.state.email == "" ||
              this.state.password == "" ||
              this.state.password_confirmation == "" ||
              this.state.password_confirmation != this.state.password
            }
            style={
              this.state.nombre == "" ||
              this.state.apellidos == "" ||
              this.state.rut == "" ||
              this.state.sexo == "" ||
              this.state.nacionalidad == "" ||
              this.state.pais_residencia == "" ||
              this.state.region_residencia == "" ||
              this.state.altura == "" ||
              this.state.peso == "" ||
              this.state.telefono == "" ||
              this.state.fecha_nacimiento == "" ||
              this.state.email == "" ||
              this.state.password == "" ||
              this.state.password_confirmation == "" ||
              this.state.password_confirmation != this.state.password 
              ? {backgroundColor: "rgb(209 213 219)"} : {}
            }
            onClick={this.registrar}
            >
              REGISTRARSE
            </button> 
            </>
        }

        <br/>
        </div>
        {/* > sm  */}
        <span className="ml-10 mb-4 hidden sm:inline text-sm sm:absolute sm:bottom-0 sm:left-0">
        ¿Ya tienes una Cuenta? 
        <Link  className="px-3 font-bold text-rojo" to="/login">LOGIN</Link>
        </span>
        <span className="mr-10 mb-4 hidden sm:inline text-sm sm:absolute sm:bottom-0 sm:right-0">
        Volver a la página  
        <Link  className="px-3 font-bold text-rojo" to="/home">INICIO</Link>
        </span>
        {/* celulares */}
        <Link  className="ml-10 mb-4 absolute bottom-0 left-0 sm:hidden px-3 font-bold text-rojo" to="/login">LOGIN</Link>
        <Link  className="mr-10 mb-4 absolute bottom-0 right-0 sm:hidden px-3 font-bold text-rojo" to="/home">INICIO</Link>

        </div>
      </div>
      <div className=" bg-negro-icono-cratus hidden w-full sm:w-1/3 sm:flex relative">
        <div className="absolute top-0 right-0 flex">
        <div className="m-3 w-8 h-8">
            <a href="https://www.facebook.com/Cratus.chile">
              <img  src={facebook} alt="icono_cratus"></img>
            </a>
          </div>

          <div className="m-3 w-8 h-8">
            <a href="https://www.instagram.com/cratus_sport/">
              <img  src={instagram} alt="icono_cratus"></img>
            </a>
          </div>

          <div className="m-3 w-8 h-8">
            <a href="youtube.com/channel/UCeMcc57l-wrs0DwD3Dhva2A/featured?view_as=subscriber">
              <img  src={youtube} alt="icono_cratus"></img>
            </a>
          </div>
          
        </div>
        <div className="m-auto">
          <img src={icono_cratus} alt="logo-conversemos"></img>
        </div>
        {/* <div className="mb-4 absolute bottom-0 w-full text-center">
          <span className="lg:text-sm  text-gray-500">UN PRODUCTO DE</span>
          <h1 className="lg:text-2xl font-bold text-gray-500">IDEAS MACHINE</h1>
        </div> */}

      </div>
      </div>
    </div>
     ) 
  }
}

export default RegisterPage;