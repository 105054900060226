import React from 'react'
import Menu from '../Menu';
import Footer from '../Footer';
import Auth from '../../middleware/Auth';

class Perfil extends React.Component{
  constructor(props){
    super(props)
    var fecha = JSON.parse(sessionStorage.getItem("user")).fecha_nacimiento
    this.state={
      user: JSON.parse(sessionStorage.getItem("user")),
      usuarios: [],
      edit_user_form_open: false,
      nombre: JSON.parse(sessionStorage.getItem("user")).nombre ,
      apellidos: JSON.parse(sessionStorage.getItem("user")).apellidos ,
      rut: JSON.parse(sessionStorage.getItem("user")).rut ,
      sexo: JSON.parse(sessionStorage.getItem("user")).sexo ,
      nacionalidad: JSON.parse(sessionStorage.getItem("user")).nacionalidad ,
      pais_residencia: JSON.parse(sessionStorage.getItem("user")).pais_residencia ,
      region_residencia: JSON.parse(sessionStorage.getItem("user")).region_residencia ,
      altura: JSON.parse(sessionStorage.getItem("user")).altura ,
      peso: JSON.parse(sessionStorage.getItem("user")).peso ,
      telefono: JSON.parse(sessionStorage.getItem("user")).telefono ,
      fecha_nacimiento: (new Date(fecha)).toLocaleDateString(),
      email: JSON.parse(sessionStorage.getItem("user")).email 
    }
    this.handleChange = (e)=>{this.setState({[e.target.name]: e.target.value})}
    this.editar = this.editar.bind(this)
  }

  edit_user_form(){
    return(
      <div className="flex ">
        <div className="w-1/4 mx-2">
          <input name="nombre" value={this.state.nombre} onChange={this.handleChange} className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="text" placeholder="Nombre"></input> 
          <input name="apellidos" value={this.state.apellidos} onChange={this.handleChange} className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="text" placeholder="Apellidos"></input> 
          <select name="sexo" value={this.state.sexo} onChange={this.handleChange} className=" py-1 px-1 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" >
            <option value="">Sexo</option>
            <option value="M">Masculino</option>
            <option value="F">Femenino</option>
          </select> 
        </div>
        <div className="w-1/4 mx-2">
          <input name="rut" value={this.state.rut} onChange={this.handleChange} placeholder="Rut" className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="text"></input>         
          <input name="telefono" value={this.state.telefono} onChange={this.handleChange} placeholder="Teléfono" className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="text"></input>         
          <input name="email" value={this.state.email} onChange={this.handleChange} placeholder="Email" className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="text"></input> 
        </div>
        <div className="w-1/4 mx-2">
        <input name="nacionalidad" value={this.state.nacionalidad} onChange={this.handleChange} placeholder="Nacionalidad" className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="text"></input>         
          <input name="pais_residencia" value={this.state.pais_residencia} onChange={this.handleChange} placeholder="País residencia" className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="text"></input>         
          <input name="region_residencia" value={this.state.region_residencia} onChange={this.handleChange} placeholder="Región residencia" className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="text"></input>         
       
        </div>
        <div className="w-1/4 mx-2">
        <input name="altura" value={this.state.altura} onChange={this.handleChange} placeholder="Altura" className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="number" step="any"></input> 
          <input name="peso" value={this.state.peso} onChange={this.handleChange} placeholder="Peso" className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="number" step="any"></input> 
          <input name="fecha_nacimiento" value={this.state.fecha_nacimiento} onChange={this.handleChange} placeholder="Fecha de nacimiento" className=" py-1 px-2 border-solid border-gray-900 placeholder-gray-800 border-2 w-full my-1 focus:bg-gray-300" type="text" onFocus={(e)=> e.target.type = "date" }></input> 

        </div>
      </div>

    )
  }

  user_info(){
    return(
      <div className="flex text-xl mt-16 italic font-bold justify-center">
        <div className=" mx-16 flex flex-col">
          <div className="m-2">{"Nombre: "+this.state.user.nombre}</div>
          <div className="m-2">{"Apellidos: "+this.state.user.apellidos}</div>
          <div className="m-2">{"Sexo: "+this.state.user.sexo}</div>
          <div className="m-2">{"Rut: "+this.state.user.rut}</div>
          <div className="m-2">{"Telefono: "+this.state.user.telefono}</div>
          <div className="m-2">{"Email: "+this.state.user.email}</div>
       
        </div>

        <div className=" mx-16 flex flex-col">
          <div className="m-2">{"Nacionalidad: "+this.state.user.nacionalidad}</div>
          <div className="m-2">{"Pais Residencia: "+this.state.user.pais_residencia}</div>
          <div className="m-2">{"Region Residencia: "+this.state.user.region_residencia}</div>
          <div className="m-2">{"Altura: "+this.state.user.altura}</div>
          <div className="m-2">{"Peso: "+this.state.user.peso}</div>
          <div className="m-2">{"Fecha nacimiento: "+(new Date(this.state.user.fecha_nacimiento)).toLocaleDateString()}</div>
        </div>
      </div>

    )
  }


  editar(){
    var url = "api/users/"+this.state.user.id
    var data = {
      nombre: this.state.nombre, 
      apellidos: this.state.apellidos, 
      rut: this.state.rut, 
      sexo: this.state.sexo, 
      nacionalidad: this.state.nacionalidad, 
      pais_residencia: this.state.pais_residencia, 
      region_residencia: this.state.region_residencia, 
      altura: this.state.altura, 
      peso: this.state.peso, 
      telefono: this.state.telefono, 
      fecha_nacimiento: this.state.fecha_nacimiento, 
    }
    console.log("editar_perfil:", url, data)
    fetch(url,{
      method: "PUT",
      headers:{
        "Content-Type": "application/json",
        'access-token': Auth.getToken().access_token,
        'client': Auth.getToken().client,
        'uid': Auth.getToken().uid
      },
      body: JSON.stringify(data)
    }).then((res)=>{ 
      Auth.updateToken(res.headers.get('access-token'));      
      return res.json()
    })
    .then((res)=>{
      console.log("res", res)
      this.setState({edit_user_form_open: false})
      sessionStorage.setItem("user", JSON.stringify(res.data))
      location.reload()
    })
  }

  render(){ 
    return(
      <div className="min-h-screen flex flex-col">
        <Menu active="usuarios"/>
          <div className="flex-grow flex flex-col p-6">
          <div className="flex justify-between">
            <div className="w-10"></div>
            <div className=" font-bold text-4xl text-rojo mx-2">
              {
                this.state.edit_user_form_open?
                "EDITAR PERFIL"
                :
                "PERFIL"
              }
              
            </div>
            <div className="flex mx-2">
              {
                this.state.edit_user_form_open?
                <>
                <button className="mx-2 italic bg-rojo text-2xl text-white font-bold py-1 px-2"
                onClick={()=>this.setState({edit_user_form_open: false})}
                > 
                CANCELAR
                </button>
                <button className="mx-2 italic bg-rojo text-2xl text-white font-bold py-1 px-2"
                onClick={this.editar}
                > 
                GUARDAR
                </button>
                </>
                :
                <button className="italic bg-rojo text-2xl text-white font-bold py-1 px-2"
                onClick={()=>this.setState({edit_user_form_open: true})}
                > 
                EDITAR
                </button>
              }


            </div>
          </div>
          {
            this.state.edit_user_form_open?
              this.edit_user_form()
            :
              this.user_info()
          }
          </div>
        {/* HomePage */}
        <Footer/>
      </div>
    )
  }
}

export default Perfil;