import React from 'react'
import icono_cratus from './../assets/img/icono_cratus.png';
import facebook from './../assets/img/facebook.png';
import youtube from './../assets/img/youtube.png';
import instagram from './../assets/img/instagram.png';
import {Link} from 'react-router-dom';

class Footer extends React.Component{
  constructor(props){
    super(props)
  }
  render(){
    return( 
    <footer className="bg-negro-icono-cratus text-white w-full flex flex-col">
      <ul className="flex justify-center " >
          <li className={this.props.active==="politicas" ?" text-rojo m-3 font-bold hover:text-orange-300": " text-white m-3 font-bold hover:text-orange-300"}>
            <Link to="/politicas_y_privacidad">POLITICAS DE PRIVACIDAD</Link>
          </li>
          <li className={this.props.active==="condiciones" ?" text-rojo m-3 font-bold hover:text-orange-300": " text-white m-3 font-bold hover:text-orange-300"}>
            <Link to="/condiciones">CONDICIONES DE USO</Link>
          </li>
          <li className="mx-16"></li>
          <li className=" text-white m-3 "><i className="fas fa-phone mx-2"></i> +56 9 1234 5678 </li>
          <li className=" text-white m-3 "><i className="far fa-envelope mx-2"></i>CONTACTO@CRATUS.CL </li>
        </ul>
        <div className="flex justify-between">
        <div className="w-16 my-auto mx-3">
          <img  src={icono_cratus} alt="icono_cratus"></img>
        </div>
        <div className="flex justify-between">
          <div className="m-3 w-8 h-8">
            <a href="https://www.facebook.com/Cratus.chile">
              <img  src={facebook} alt="icono_cratus"></img>
            </a>
          </div>

          <div className="m-3 w-8 h-8">
            <a href="https://www.instagram.com/cratus_sport/">
              <img  src={instagram} alt="icono_cratus"></img>
            </a>
          </div>

          <div className="m-3 w-8 h-8">
            <a href="youtube.com/channel/UCeMcc57l-wrs0DwD3Dhva2A/featured?view_as=subscriber">
              <img  src={youtube} alt="icono_cratus"></img>
            </a>
          </div>
        </div>
      </div>
    </footer>
    )
  }
}

export default Footer;