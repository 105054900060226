import React from 'react';
import {Link} from 'react-router-dom';
import icono_cratus from './../assets/img/icono_cratus.png';
import Auth from './../middleware/Auth';
class Menu extends React.Component{
  constructor(props){
    super(props)
    console.log(props)
    this.state={
      user: JSON.parse(sessionStorage.getItem("user")),
      open_dropdown: false
    }
  }

  logout(){
    var url = "api/auth/sign_out"

    fetch(url,{
      method: "DELETE",
      headers:{
        'access-token': Auth.getToken().access_token,
        'client': Auth.getToken().client,
        'uid': Auth.getToken().uid
      }
    }).then((res)=>{
      console.log("logout res",res) 
      // if(res.status == 200){
        Auth.deauthenticateUser();
        sessionStorage.removeItem("user");
        window.location.href = "/login";
      // } 

    })
  }

  render(){
    console.log("this.state", this.state)
    return( 
    <header className="relative bg-negro-icono-cratus flex-shrink flex justify-between h-20">
      <div className="mt-10 ml-10">
        <ul className="flex " >

          {
            this.state.user?
              <>
                {
                  this.state.user.is_admin?
                  <li className={this.props.active==="usuarios" ?" text-rojo m-3 font-bold hover:text-orange-300": " text-white m-3 font-bold hover:text-orange-300"}>
                    <Link to="/usuarios">USUARIOS</Link>
                  </li>
                  :
                  null
                }

                <li className={this.props.active==="rutinas"?" text-rojo m-3 font-bold hover:text-orange-300": " text-white m-3 font-bold hover:text-orange-300"}>
                  <Link to="/rutinas">RUTINAS</Link>
                </li>
                <li className={this.props.active==="estadisticas"?" text-rojo m-3 font-bold hover:text-orange-300": " text-white m-3 font-bold hover:text-orange-300"}>
                  <Link to="/estadisticas">ESTADISTICAS</Link>
                </li>
              </>
                :
              <>
                <li className={this.props.active==="home" ?" text-rojo m-3 font-bold hover:text-orange-300": " text-white m-3 font-bold hover:text-orange-300"}>
                  <Link to="/home">INICIO</Link>
                </li>
                <li className={" invisible text-white m-3 font-bold hover:text-orange-300"}>
                <Link to="/rutinas">RUTINAS</Link>

                </li>
                <li className={ " invisible text-white m-3 font-bold hover:text-orange-300"}>
                <Link to="/estadisticas">ESTADISTICAS</Link>

                </li>
              </>
          }

        </ul>
      </div>
      <div className="self-center w-16 my-auto">

        <img src={icono_cratus}  alt="icono_cratus"></img>
      </div>
      <div className="mt-10 mr-10">
        <ul className="flex " >
          <li className={this.props.active==="dudas" ?" text-rojo m-3 font-bold hover:text-orange-300": " text-white m-3 font-bold hover:text-orange-300"}>
            <Link to="/faq">FAQ</Link>
          </li>
          <li className={this.props.active==="contacto" ?" text-rojo m-3 font-bold hover:text-orange-300": " text-white m-3 font-bold hover:text-orange-300"}>
            <Link to="/contacto">CONTACTO</Link>
          </li>
          {
            this.state.user ?
            <li 
            onClick={()=>this.setState({open_dropdown: !this.state.open_dropdown})}
            className={this.props.active==="perfil" ?" text-rojo m-3 font-bold hover:text-orange-300 cursor-pointer": " cursor-pointer text-white m-3 font-bold hover:text-orange-300"}>
              {this.state.user.nombre}
            </li>
            :
            <li className={this.props.active==="login"?" text-rojo m-3 font-bold hover:text-orange-300": " text-white m-3 font-bold hover:text-orange-300"}>
              <Link to="/login">ENTRAR</Link>
            </li>
          }

        </ul>
      </div>
      {
        this.state.open_dropdown?
        <div className="absolute cursor-pointer z-5 right-0 flex flex-col bg-negro-icono-cratus" style={{bottom: "-3rem"}}>
          <div className={"hover:bg-black mx-2 text-white font-bold hover:text-orange-300"}>
            <Link to="/perfil">PERFIL</Link>
          </div>
          <div 
          onClick={this.logout}
          className={"hover:bg-black mx-2 text-white font-bold hover:text-orange-300"}>
            CERRAR SESION
          </div>
        </div>
        :
        null
      }
    </header>
    )
  }
}

export default Menu;