import React from 'react';
import Menu from './../Menu';
import Footer from './../Footer';
import icon_burbuja_naranja from './../../assets/img/icon_burbuja_naranja.png'
import {Link} from 'react-router-dom'
class ConsultasHistoricasPage extends React.Component{
  constructor(props){
    super(props)
  }
  render(){
    return(
      <div className="min-h-screen flex flex-col">
      <Menu active="dudas"/>
      <div className="  page_content  flex-grow p-10">
      <h1 className="text-rojo text-4xl font-bold mb-8 text-left">Preguntas Frecuentes</h1>

      <div className="flex items-center h-10 w-full hover:bg-gray-400 text-xl bg-gray-300 rounded-full px-2 py-1">
            <div>
              <img className="inline mr-4" src={icon_burbuja_naranja}></img>
            </div>
            <div>
              <h2 > ¿Qué pasa si ... ? </h2>
            </div>
          </div>
      <br/>

      <p className="ml-4">
 	      <b>  R:</b> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi maximus placerat purus, sed imperdiet leo porta et. Nullam mattis lectus ligula, in mollis sem tristique ac.        
       <br/>
       Phasellus lobortis erat augue, eget venenatis ex posuere sit amet. Ut eu enim dui. Aenean nec augue quam. Fusce feugiat est eu enim malesuada, ac maximus nibh rhoncus. Nulla iaculis purus nisl, ac consequat ipsum fermentum quis. Vestibulum aliquam mollis quam, ut lacinia dolor ornare viverra. 
       </p>
       <br/>
      <div className="flex items-center h-10 w-full hover:bg-gray-400 text-xl bg-gray-300 rounded-full px-2 py-1">
            <div>
              <img className="inline mr-4" src={icon_burbuja_naranja}></img>
            </div>
            <div>
              <h2 > ¿Como hago ...?</h2>
            </div>
          </div>
      <br/>
      <p className="ml-4">
      <b>  R:</b> Aliquam ultrices faucibus erat, eu elementum odio placerat ac. Morbi elit neque, placerat vel viverra a, mattis a lorem. Nam pellentesque eleifend velit, ultricies sodales elit posuere eget.
      </p>
      <br/>

      <div className="flex items-center h-10 w-full hover:bg-gray-400 text-xl bg-gray-300 rounded-full px-2 py-1">
            <div>
              <img className="inline mr-4" src={icon_burbuja_naranja}></img>
            </div>
            <div>
              <h2 > ¿Cómo puedo... ?</h2>
            </div>
          </div>
      <br/>

      <p className="ml-4">
        <b>  R:</b> Proin augue lectus, suscipit et ipsum ut, lobortis tempus lacus. Donec sodales lorem a erat semper iaculis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae nisl in mauris fringilla condimentum. 
      </p>
      <br/>

      <div className="flex items-center h-10 w-full hover:bg-gray-400 text-xl bg-gray-300 rounded-full px-2 py-1">
            <div>
              <img className="inline mr-4" src={icon_burbuja_naranja}></img>
            </div>
            <div>
              <h2 > ¿Cómo saber una...?</h2>
            </div>
          </div>
      <br/>

 	    <p className="ml-4">
        <b>  R:</b> Pellentesque in turpis rhoncus, facilisis sem vitae, facilisis libero.
        <br/>
        <br/>
        Morbi pretium, turpis nec feugiat egestas, neque diam rutrum enim, id porttitor magna velit vitae odio. Integer est tellus, iaculis in tristique ut, ullamcorper at dolor.        <br/>
        <br/>
        Donec egestas felis vel velit dapibus, et pretium nulla finibus. Curabitur magna justo, finibus et suscipit sed, ultricies ut nulla.

Nam rhoncus mauris at tincidunt blandit. </p>
      </div>
      <Footer/>
    </div>
    )
  }

}
export default ConsultasHistoricasPage;