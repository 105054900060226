import React from 'react';
import Menu from './../Menu';
import Footer from './../Footer';
import icon_burbuja_naranja from './../../assets/img/icon_burbuja_naranja.png'
import {Link} from 'react-router-dom'

class ConsultasHistoricasPage extends React.Component{
  constructor(props){
    super(props)
  }
  render(){
    return(
      <div className="min-h-screen flex flex-col">
      <Menu />
      <div className="  page_content flex-grow p-10">
      <h1 className="text-rojo text-4xl font-bold text-left">POLÍTICA DE PRIVACIDAD</h1>
      <h2 className="text-sm font-bold mb-8 text-left">En vigencia a partir del 2 de Junio de 2020</h2>
      <p>
      Esta política de privacidad ("Política de privacidad") describe cómo Cratus Spa. ("Cratus" o "nosotros" o el "Controlador de datos") recopila y utiliza su información y datos personales a través de una variedad de medios digitales medio. Al acceder o utilizar el sitio web de Cratus, las aplicaciones móviles de Cratus u otros productos o servicios de Cratus en cualquier computadora, teléfono móvil, tableta, consola o
otro dispositivo (colectivamente, el "Dispositivo"), usted acepta nuestra Política de privacidad.
Cratus puede modificar esta Política de privacidad en cualquier momento a partir de su publicación. Su uso continuo de nuestro los productos y servicios constituyen su aceptación de esta Política de privacidad y cualquier actualización de la misma.
Esta Política de privacidad está incorporada y está sujeta a los Términos y condiciones.
Cualquier procesamiento de datos personales del Usuario bajo esta Política de Privacidad se llevará a cabo de conformidad con la Ley de Protección de Datos de Chile. 
      </p>
      <div className="mb-4">
        <div className="flex items-center h-10 w-full hover:bg-gray-400 text-xl bg-gray-300 rounded-full px-2 py-1">
          <div>
          <img className="inline mr-4" src={icon_burbuja_naranja}></img>
          </div>
          <div>
          <h2 >  Controlador de datos y propietario: </h2>

          </div>
        </div>
        
        <p className="ml-8 my-8">
        Los usuarios que utilizan los productos y servicios, aplicaciones, sitio web, proporcionados por Cratus (los "Usuarios"), incluidos, entre otros, los usuarios de la aplicación "Cratus" y el sitio web de Cratus, reconoce que el controlador de datos de sus datos personales e información en Cratus Spa. - “Echevers 185, oficina 96, Viña del Mar, Chile”, info@cratus.cl Cratus procesa los datos de los Usuarios de manera adecuada, de acuerdo con los términos y condiciones establecidos más adelante en esta Política de privacidad, y tomará las medidas de seguridad adecuadas para evitar que no estén autorizados acceso, divulgación, modificación o destrucción no autorizada de la misma.
        </p>
      </div>
      <div className="mb-4">
        <div className="flex items-center h-10 w-full hover:bg-gray-400 text-xl bg-gray-300 rounded-full px-2 py-1">
          <div>
          <img className="inline mr-4" src={icon_burbuja_naranja}></img>
          </div>
          <div>
          <h2 >  Información recopilada: </h2>

          </div>
        </div>
        
        <p className="ml-8 my-8">
        Recopilamos los siguientes datos e información (colectivamente, la "Información"):
        <ul>
          <li>
          a. información que nos proporciona o nos permite acceder: este tipo de información puede incluir, pero es no limitado a, su nombre, imagen, fecha de nacimiento, correo electrónico y dirección física, número de teléfono, género, listas de contactos, información y perfil de redes sociales, actividad y desempeño información, y cuando sea necesario, información de tarjeta de crédito;
          </li>
          <li>
          b. los datos de sus desempeños deportivos (incluidos, entre otros, aceleración, velocidad, Poder, Fuerza, Explosividad, Volumen, Elevación, Intensidad, Elevación, Densidad, Promedio, Potencia, Índice de fatiga 1RM (1 repetición máxima) ESD (déficit de fuerza explosiva) RFD (tasa de desarrollo de fuerza) IES (índice de fuerza explosiva) RC (coeficiente de reactividad) ISS (índice de fuerza inicial) TUT (Tiempo bajo tensión);
          </li>
          <li>
          c. datos e información con respecto a su interacción y uso de nuestros productos y servicios. La información que podemos recopilar a este respecto incluye, entre otros, su teléfono número, identificador del dispositivo e información de hardware, dirección IP, tipo de navegador e idioma, información de cookies, tipo de sistema, si tiene software habilitador para acceder a ciertas características, tiempos de acceso, URL de sitios web de referencia, información sobre sus compras y otros Información sobre sus interacciones con nosotros.
          </li>
        </ul>
        <br></br>
            Los datos personales de los Usuarios pueden ser proporcionados libremente por el Usuario, o recopilados automáticamente utilizando las aplicaciones, software, plataformas, dispositivos y sitios web de Cratus. Los usuarios son responsables de cualquier dato o información obtenida de terceros, publicada o compartida a través de las aplicaciones, el software, las plataformas y los sitios web de Cratus y confirman que un tercero tiene consentimiento de la parte para proporcionar dichos datos e información.
            El usuario es el único responsable de la exactitud, integridad y veracidad de los datos y
            información proporcionada, grabada o cargada o, sin embargo, puesta a disposición de Cratus.
        </p>
      </div>
      <div className="mb-4">
        <div className="flex items-center h-10 w-full hover:bg-gray-400 text-xl bg-gray-300 rounded-full px-2 py-1">
          <div>
          <img className="inline mr-4" src={icon_burbuja_naranja}></img>
          </div>
          <div>
          <h2 >  Interacción con terceros para la adquisición de datos: </h2>

          </div>
        </div>
        
        <p className="ml-8 my-8">
        Como resultado, podemos combinar la información que nos brinda con otra información disponible directamente para Cratus de cualquier carga realizada por usted (en el sitio web, en relación con la compra o de otra manera). Podemos
también combinar esa información con datos que están disponibles públicamente y datos de terceros. Nosotros también podemos recopilar información sobre los destinatarios del regalo proporcionada por el donante.
        </p>
      </div>
      <div className="mb-4">
        <div className="flex items-center h-10 w-full hover:bg-gray-400 text-xl bg-gray-300 rounded-full px-2 py-1">
          <div>
          <img className="inline mr-4" src={icon_burbuja_naranja}></img>
          </div>
          <div>
          <h2 >  Cómo recopilamos información: </h2>

          </div>
        </div>
        
        <p className="ml-8 my-8">
        Cratus puede recopilar automáticamente información dentro de nuestros productos y servicios o almacenada por su navegador o dispositivo. Utilizamos una variedad de métodos para recopilar esta información, incluidos, entre otros, cookies y etiquetas de píxeles / balizas web ("Herramientas de recopilación").
        </p>
      </div>
      <div className="mb-4">
        <div className="flex items-center h-10 w-full hover:bg-gray-400 text-xl bg-gray-300 rounded-full px-2 py-1">
          <div>
          <img className="inline mr-4" src={icon_burbuja_naranja}></img>
          </div>
          <div>
          <h2 >  Privacidad de los niños: </h2>

          </div>
        </div>
        
        <p className="ml-8 my-8">
        No recopilamos ni solicitamos a sabiendas información personal de niños menores de 18 años.
        </p>
      </div>
      <div className="mb-4">
        <div className="flex items-center h-10 w-full hover:bg-gray-400 text-xl bg-gray-300 rounded-full px-2 py-1">
          <div>
          <img className="inline mr-4" src={icon_burbuja_naranja}></img>
          </div>
          <div>
          <h2 >  Uso de la información y el procesamiento de datos: </h2>

          </div>
        </div>
        
        <p className="ml-8 my-8">
        Podemos usar (incluido el uso, la grabación, el almacenamiento y el procesamiento) su información para hacer lo siguiente:
        <ul>
          <li>
          a. le permite usar las funciones de la aplicación "Cratus" y los sitios web de Cratus, como registro del usuario en nuestro sitio web, cálculo y registro de su deporte y actividades (incluidas, entre otras, aceleración, velocidad, potencia, fuerza, Explosividad, volumen, elevación, intensidad, elevación, densidad, promedio, potencia, índice de fatiga 1RM (1 repetición máxima) ESD (déficit de fuerza explosiva) RFD (tasa de fuerza desarrollo) IES (índice de fuerza explosiva) RC (coeficiente de reactividad) ISS (índice de fuerza inicial) TUT (Tiempo bajo tensión);
          </li>
          <li>
          b. mejore y personalice su experiencia de la aplicación "Cratus" y del sitio web de Cratus y comunicaciones con cualquier dispositivo de Cratus; 
          </li>
          <li>
          c. operar, proporcionar, mejorar y mantener los productos y servicios de Cratus, incluido el análisis Comportamiento, hábitos, actuaciones, resultados deportivos, actividad deportiva y tendencias del usuario;
          </li>
          <li>
          d. cumplir con cualquier obligación y hacer cumplir los derechos relacionados con la venta y el uso de los dispositivos de Cratus, productos y servicios, incluso en relación con la aplicación y procedimientos judiciales, y realizar cualquier obligación contable, fiscal y legal relacionada con la misma (ya sea meramente interna o externo);
          </li>
          <li>
          e. enviarle mensajes administrativos y comunicarse con usted sobre su compra, cuenta información o servicio al cliente; enviar facturas (si corresponde); 
          </li>
          <li>
          f. enviarle mensajes promocionales y comerciales y otra información sobre Cratus, productos y servicios y eventos de Cratus (por correo, correo electrónico y SMS); enviarle boletines informativos (a través de mensajería, correo electrónico y SMS); realizar actividades comerciales y de venta (incluidas directas / indirectas) de venta);
          </li>
          <li>
          g. procesamiento / almacenamiento / registro de la Información, incluso para (i) los propósitos de desarrollar nuevos productos y servicios; y (ii) fines contables, legales y fiscales (facturación y gerencia Administrativa); 
          </li>
          <li>
          h. procesamiento / almacenamiento / registro de la información para fines de "big data" y / o para el mercado fines de investigación, fines estadísticos y / o analizar sus gustos / preferencias y hábitos (ya sea en forma agregada o desagregada);
          </li>
          <li>
          i. vender y / o transferir, directa o indirectamente, su información, ya sea anónima o no, en forma aislada, desagregada o agregada, a cualquier tercero para fines de "big data" y / o para fines comerciales (incluso para la entrega de materiales comerciales y directa / indirecta venta), y / o con fines de marketing / estadística / investigación, en Chile o en cualquier país de la Unión Europea, y/o del mundo.
          </li>
        </ul>
        <br></br>
        Se entiende que, en la medida permitida y otorgada bajo las leyes aplicables, los datos y La información sobre la cual a Cratus se le ha otorgado el derecho de uso a continuación se considerará y considerado como propiedad de Cratus, siempre que se cumplan los derechos y limitaciones establecidos bajo las leyes y regulaciones aplicables de Chile.
        </p>
      </div>
      <div className="mb-4">
        <div className="flex items-center h-10 w-full hover:bg-gray-400 text-xl bg-gray-300 rounded-full px-2 py-1">
          <div>
          <img className="inline mr-4" src={icon_burbuja_naranja}></img>
          </div>
          <div>
          <h2 > Métodos de procesamiento: </h2>

          </div>
        </div>
        
        <p className="ml-8 my-8">
        La información se procesará utilizando herramientas y procedimientos que sean apropiados para asegurar seguridad y confidencialidad de los datos, y puede hacerse tanto en papel como en papel herramientas de procesamiento de datos.
        </p>
      </div>
      <div className="mb-4">
        <div className="flex items-center h-10 w-full hover:bg-gray-400 text-xl bg-gray-300 rounded-full px-2 py-1">
          <div>
          <img className="inline mr-4" src={icon_burbuja_naranja}></img>
          </div>
          <div>
          <h2 > Lugar: </h2>

          </div>
        </div>
        
        <p className="ml-8 my-8">
        La información se p La información se procesa en las oficinas operativas del controlador de datos y en cualquier otro lugar donde se localizan las partes involucradas en el procesamiento.rocesará utilizando herramientas y procedimientos que sean apropiados para asegurar seguridad y confidencialidad de los datos, y puede hacerse tanto en papel como en papel herramientas de procesamiento de datos.
        </p>
      </div>
      <div className="mb-4">
        <div className="flex items-center h-10 w-full hover:bg-gray-400 text-xl bg-gray-300 rounded-full px-2 py-1">
          <div>
          <img className="inline mr-4" src={icon_burbuja_naranja}></img>
          </div>
          <div>
          <h2 > Tiempo de retención: </h2>

          </div>
        </div>
        
        <p className="ml-8 my-8">
        La información se conserva durante 15 años, y el usuario siempre puede solicitar que el controlador de datos suspenda o eliminar sus datos / información.         
        </p>
      </div>
      <div className="mb-4">
        <div className="flex items-center h-10 w-full hover:bg-gray-400 text-xl bg-gray-300 rounded-full px-2 py-1">
          <div>
          <img className="inline mr-4" src={icon_burbuja_naranja}></img>
          </div>
          <div>
          <h2 > Obligación de proporcionar los datos solicitados: </h2>

          </div>
        </div>
        
        <p className="ml-8 my-8">
        La información que recopilamos de usted es necesaria para el desempeño regular de las funciones de la aplicación "Cratus" y los servicios y características del sitio web de Cratus. Se debe notar que la no disposición, la imposibilidad de adquirir o la posterior solicitud de eliminación de cualquier dato requerido, causará la imposibilidad de iniciar o continuar dichos servicios, características y uso por parte del Usuario.
        </p>
      </div>
      <div className="mb-4">
        <div className="flex items-center h-10 w-full hover:bg-gray-400 text-xl bg-gray-300 rounded-full px-2 py-1">
          <div>
          <img className="inline mr-4" src={icon_burbuja_naranja}></img>
          </div>
          <div>
          <h2 > Comunicación, difusión y conocimiento de los datos: </h2>

          </div>
        </div>
        
        <p className="ml-8 my-8">
        Los datos no se transmitirán a personas o entidades no especificadas de ninguna forma, excepto de lo contrario se estipula a continuación. Los datos pueden comunicarse a una o más personas específicas o entidades bajo los siguientes términos:
        <ul>
          <li>
          a. a los empleados de Cratus;
          </li>
          <li>
          b. a las autoridades y agencias públicas (incluyendo Garante para la Protección de Datos Personales);
          </li>
          <li>
          c. personas o entidades que pueden acceder a los datos de conformidad con las leyes y regulaciones nacionales y de la UE o sobre la base de decisiones judiciales, dentro de los límites de dichas leyes, reglamentos y decisiones;
          </li>
          <li>
          d. en algunos casos, los datos pueden ser accesibles para ciertos tipos de personas a cargo y / o involucrado con la operación del sitio (administración, ventas, marketing, legal, sistema administración) o terceros (como proveedores de servicios técnicos de terceros, correo operadores, proveedores de alojamiento, empresas de TI, agencias de comunicaciones) dentro de los límites estrictamente requerido para ejecutar tales servicios;
          </li>
          <li>
          e. a las personas y entidades que necesitan acceso a los datos para fines auxiliares a las disposiciones de los servicios regulares de Cratus, dentro de los límites estrictamente requeridos para ejecutar dicho auxiliar servicios (incluidos, entre otros, empresas de recuperación de crédito, servicios postales y similares, institutos de vigilancia, proveedores de TIC);
          </li>
          <li>
          f. a otras compañías que pueden ser designadas, en outsourcing, para administrar ciertos servicios;
          </li>
          <li>
          g. a los procesadores de datos (si se designa), dentro de los límites requeridos para llevar a cabo su cita con la organización, siguiendo la carta de nombramiento que garantiza la confidencialidad y seguridad o su nombramiento como procesadores de datos.
          </li>
        </ul>
        </p>
      </div>
      <div className="mb-4">
        <div className="flex items-center h-10 w-full hover:bg-gray-400 text-xl bg-gray-300 rounded-full px-2 py-1">
          <div>
          <img className="inline mr-4" src={icon_burbuja_naranja}></img>
          </div>
          <div>
          <h2 > Derechos del usuario:</h2>

          </div>
        </div>
        
        <p className="ml-8 my-8">
        El usuario tiene derecho a acceder a los datos personales que le conciernen y puede ejercer los derechos.
establecido en Ley de Protección de Datos de Chile contactando a Cratus en los datos de contacto anteriores previsto por los artículos de la Ley de Protección de Datos de Chile. El acceso a la información puede tener costos asociados.
El Usuario tiene los siguientes derechos:
        <ul>
          <li>
          h. El derecho a obtener información general sobre las operaciones de procesamiento realizadas en Chile por Acceder, con cargos, al Registro de Operaciones de Procesamiento en línea que mantiene en Chile la Autoridad de Protección de Datos (si corresponde);
          </li>
          <li>
          i. El derecho a acceder a sus propios datos personales directamente desde Cratus, incluso con el propósito de obtener confirmación de que tales datos existen y la comunicación de los datos, así como a Conocer la fuente de los datos y qué criterios y propósitos se aplican a su procesamiento. En último caso, Cratus puede cobrar una tarifa ("tarifa de manejo") si se determina que no hay datos relacionados con el sujeto de datos;
          </li>
          <li>
          j. El derecho a obtener la eliminación, eliminación o bloqueo de cualquier información que se procese en los casos provisto por la ley; si lo permite la ley, Cratus puede conservar ciertos derechos sobre dichos datos como estrictamente necesario para fines legales, fiscales o fiscales;
          </li>
          <li>
          k. el derecho a tener datos inexactos y / o incompletos actualizados, rectificados o complementados;
          </li>
          <li>
          l. el derecho a que sus datos sean anónimos;
          </li>
          <li>
          m. el derecho a oponerse al procesamiento de sus propios datos en los casos previstos por la ley;
          </li>
          <li>
          n. El derecho a oponerse, en todos los casos, al procesamiento de sus propios datos para fines comerciales, con fines informativos y / o para enviar publicidad o materiales de venta directa y / o para fines de investigación de mercado.
          </li>
        </ul>
        </p>
      </div>
      <div className="mb-4">
        <div className="flex items-center h-10 w-full hover:bg-gray-400 text-xl bg-gray-300 rounded-full px-2 py-1">
          <div>
          <img className="inline mr-4" src={icon_burbuja_naranja}></img>
          </div>
          <div>
          <h2 > Compartir información:</h2>

          </div>
        </div>
        
        <p className="ml-8 my-8">
        Cratus puede proporcionar su información a las subsidiarias y afiliadas de Cratus siempre que estén ubicadas en la Unión Europea o EE. UU o donde Cratus estime conveniente.
        </p>
      </div>
      <div className="mb-4">
        <div className="flex items-center h-10 w-full hover:bg-gray-400 text-xl bg-gray-300 rounded-full px-2 py-1">
          <div>
          <img className="inline mr-4" src={icon_burbuja_naranja}></img>
          </div>
          <div>
          <h2 >  Proveedores de servicios: </h2>

          </div>
        </div>
        
        <p className="ml-8 my-8">
        Podemos transferir su información a los proveedores de servicios de Cratus para llevar a cabo nuestros negocios. Por ejemplo, pueden manejar procesamiento de tarjetas de crédito, envío, gestión de datos, distribución de correo electrónico, mercado
investigación, análisis de información y gestión de promociones. También podemos compartir su información con administrar funciones (por ejemplo, descarga de música, registro de carrera o rutina de ejercicios).
        </p>
      </div>
      <div className="mb-4">
        <div className="flex items-center h-10 w-full hover:bg-gray-400 text-xl bg-gray-300 rounded-full px-2 py-1">
          <div>
          <img className="inline mr-4" src={icon_burbuja_naranja}></img>
          </div>
          <div>
          <h2 > Por ley o para proteger los derechos </h2>

          </div>
        </div>
        
        <p className="ml-8 my-8">
        Podemos divulgar información a solicitud gubernamental, en respuesta a una orden judicial, cuando sea necesario por ley, para hacer cumplir nuestras políticas o para proteger nuestros derechos, propiedad o seguridad, o los de otros. Podemos compartir información para evitar usos ilegales de los productos y servicios de Cratus o violaciones de los Términos de uso, o para defendernos de reclamos de terceros. También podemos compartir información con empresas, ayudando en la protección o investigación de fraude.
        </p>
      </div>
      <div className="mb-4">
        <div className="flex items-center h-10 w-full hover:bg-gray-400 text-xl bg-gray-300 rounded-full px-2 py-1">
          <div>
          <img className="inline mr-4" src={icon_burbuja_naranja}></img>
          </div>
          <div>
          <h2 >  Transferencias de negocios:</h2>

          </div>
        </div>
        
        <p className="ml-8 my-8">
        Su información puede ser transferida a un tercero como parte de nuestros activos comerciales en la venta de una parte o toda de Cratus.
        </p>
      </div>
      <div className="mb-4">
        <div className="flex items-center h-10 w-full hover:bg-gray-400 text-xl bg-gray-300 rounded-full px-2 py-1">
          <div>
          <img className="inline mr-4" src={icon_burbuja_naranja}></img>
          </div>
          <div>
          <h2 > Información de protección: </h2>

          </div>
        </div>
        
        <p className="ml-8 my-8">
        Utilizamos una variedad de medidas de seguridad, incluidas herramientas de cifrado y autenticación, para ayudar a proteger tu información. Utilizamos servidores seguros cuando realiza pedidos.
        </p>
      </div>
      <div className="mb-4">
        <div className="flex items-center h-10 w-full hover:bg-gray-400 text-xl bg-gray-300 rounded-full px-2 py-1">
          <div>
          <img className="inline mr-4" src={icon_burbuja_naranja}></img>
          </div>
          <div>
          <h2 > Sin garantía </h2>

          </div>
        </div>
        
        <p className="ml-8 my-8">
        Sin embargo, como otras compañías, Cratus no puede garantizar al 100% la seguridad o confidencialidad de información que nos proporciona.
        </p>
      </div>
      <div className="mb-4">
        <div className="flex items-center h-10 w-full hover:bg-gray-400 text-xl bg-gray-300 rounded-full px-2 py-1">
          <div>
          <img className="inline mr-4" src={icon_burbuja_naranja}></img>
          </div>
          <div>
          <h2 > Intercambio público: </h2>

          </div>
        </div>
        
        <p className="ml-8 my-8">
        Cuando te unes o utilizas ciertos servicios (incluidos Facebook, Twitter, Instagram, YouTube, etc), acepta compartir públicamente una cantidad básica de información, que puede incluir su nombre de usuario, ubicación de la ciudad, foto de perfil, hora y ubicación de su conexión a Internet y ciertos datos relacionado con su actividad deportiva y actuaciones. Usted es la única parte responsable de tales decisiones. Te invitamos a leer las políticas de privacidad de esas plataformas (incluyendo Facebook, Twitter, Instagram, YouTube, etc), porque las actividades y los datos de Cratus se publicaron en esas plataformas ya no se regirá por la Política de privacidad de Cratus.

El sitio web de Cratus y las aplicaciones de Cratus utilizan los complementos de redes sociales proporcionado por Facebook Inc., 1601 South California Avenue, Palo Alto, CA 94304, EE. UU. (en adelante "PENSIÓN COMPLETA"). Los complementos se identifican mediante un logotipo de FB o el complemento "FB Social Plugin". Cuando visitas una
página de nuestro sitio web que contiene un complemento social, su navegador establece una conexión directa a Servidores de Facebook. Facebook transfiere directamente el contenido del complemento a su navegador que incorpora el
este último en el sitio web, lo que permite a Facebook recibir información sobre usted al acceder a página respectiva del sitio web de Cratus. No tenemos influencia ni control sobre los datos recopilados por FB. Cuando haga clic en el complemento FB mientras está conectado en su cuenta FB, podrá enlace el contenido de las páginas web de Cratus a su perfil de FB o interactúe con los complementos, por ejemplo, haciendo clic en "Me gusta" o ingresando un comentario. Si no desea que Facebook atribuya su visita a la Sitio web de Cratus en su cuenta de FB, cierre sesión en su cuenta de FB.
El sitio web de Cratus y las aplicaciones de Cratus que utilizan los complementos de redes sociales proporcionado por Twitter (en adelante "TW"). Los complementos se identifican con un logotipo TW o el complemento "TW Complemento social ". Cuando visita una página de nuestro sitio web que contiene un complemento social, su navegador establece una conexión directa a los servidores de Twitter. Twitter transfiere directamente el contenido del complemento a su navegador que incorpora este último en el sitio web, lo que permite a Twitter recibir información sobre usted habiendo accedido a la página respectiva del sitio web de Cratus. No tenemos influencia ni control sobre los datos recopilados por el complemento. Cuando hace clic en el complemento TW mientras está conectado en su TW cuenta, podrá vincular el contenido de las páginas web de Cratus a su perfil TW o interactuar con Los complementos. Si no desea que Twitter atribuya su visita al sitio web de Cratus a su cuenta de Twitter, cierre sesión en su cuenta TW.
El sitio web de Cratus y las aplicaciones de Cratus utilizan los complementos de redes sociales proporcionado por Instagram o YouTube o Tumblr, etc. Los complementos se identifican por el respectivo Instagram o el logotipo de YouTube o Tumblr o el complemento "Instagram Social Plugin" o "YouTube Social Plugin" o "Tumblr Social Plugin"). Cuando visita una página de nuestro sitio web que contiene un complemento social, el navegador establece una conexión directa con dichos servidores. Instagram o YouTube o Tumblr o cualquier red social, directamente transfiere el contenido del complemento a su navegador que lo incorpora al sitio web, lo que permite Instagram o YouTube o Tumblr o cualquier red social para recibir información sobre usted accediendo a los respectivos página del sitio web de Cratus. No tenemos influencia ni control sobre los datos recopilados por el complemento. 
Cuando hace clic en el complemento de Instagram o YouTube o Tumblr  u otro mientras está conectado en su Cuenta de Instagram o YouTube o Tumblr, podrá vincular el contenido de las páginas web de Cratus a su perfil de Instagram, YouTube, Tumblr u otro o interactúe con los complementos. Si tu no quieres Facebook para atribuir su visita al sitio web de Cratus a su cuenta de Instagram, YouTube, Tumbler,
cierre sesión en su cuenta de Instagram, YouTube, Tumblr u otro.
        </p>
      </div>
      <div className="mb-4">
        <div className="flex items-center h-10 w-full hover:bg-gray-400 text-xl bg-gray-300 rounded-full px-2 py-1">
          <div>
          <img className="inline mr-4" src={icon_burbuja_naranja}></img>
          </div>
          <div>
          <h2 >  Uso de cookies: </h2>

          </div>
        </div>
        
        <p className="ml-8 my-8">
        Cratus y sus proveedores de servicios pueden colocar cookies (definidas como un conjunto de datos generados por un sitio web y almacenado en el disco duro o en la memoria temporal en la computadora de un usuario al iniciar sesión en las páginas
de ciertos sitios web) en su computadora cuando visita el sitio web de Cratus.
El propósito de usar cookies es obtener información estadística y recordar alguna información sobre el comportamiento de navegación del usuario (por ejemplo, para recordar preferencias, reconocer usuarios, proporcionar un servicio personalizado basado en intereses específicos, medir el tráfico y las actividades durante las visitas al sitio web de Cratus, para controlar y mejorar nuestro servicio y para evitar fraudes).
Estas cookies no guardan ningún tipo de información confidencial sobre los usuarios, ya que son anónimas. La información que Cratus se conserva en sus servidores mediante cookies consiste únicamente en un número que identifica a un usuario, además de preferencias como el idioma de navegación utilizado y la fecha en
que se envió la cookie. Cratus puede registrar su IP (Protocolo de Internet), como la identidad de la computadora desde la que accede la web, para diagnosticar cualquier problema con su servidor, administrar sus páginas web y analizar las tendencias de los usuarios.
Si no desea que la computadora lo recuerde como usuario de Cratus, será suficiente con verificar "cerrar sesión" cada vez que abandona el sitio web, de lo contrario, cada vez que inicia sesión a través de la misma computadora, el usuario será reconocido automáticamente sin la necesidad de iniciar sesión con el correo electrónico
nombre y contraseña. Si lo desea, puede configurar su navegador para evitar la recepción de cookies y evitar su instalación en su disco duro. Sin embargo, si elige esta configuración, es posible que no tenga acceso a ciertas áreas del sitio web, y no podrán aprovechar algunos de nuestros servicios. Si la configuración del navegador no se ha ajustado de tal manera que rechace las cookies, el sistema producir cookies desde el acceso al sitio web. Para obtener más información sobre el almacenamiento de cookies, consulte las instrucciones y manuales de su navegador
        </p>
      </div>
      <div className="mb-4">
        <div className="flex items-center h-10 w-full hover:bg-gray-400 text-xl bg-gray-300 rounded-full px-2 py-1">
          <div>
          <img className="inline mr-4" src={icon_burbuja_naranja}></img>
          </div>
          <div>
          <h2 > Uso de correo electrónico de invitación: </h2>

          </div>
        </div>
        
        <p className="ml-8 my-8">
        Como usuario del sitio web de Cratus, tiene la oportunidad de enviar correos electrónicos de invitación que incluyen recomendaciones a tus amigos El destinatario verá la dirección del remitente y un mensaje de texto personal con recomendación que puede agregar. Asegúrese de enviar solo estos correos electrónicos a personas reales
quienes han dado su consentimiento explícito para recibir estos correos electrónicos.
La plataforma de tecnología Cratus solo enviará correos electrónicos a las direcciones de correo electrónico que usted indique y no haremos uso de estos datos para cualquier otro propósito. No está autorizado a utilizar la herramienta de correo electrónico de invitación para enviar spam o información que esté en conflicto con los términos y condiciones generales de la empresa.
        </p>
      </div>
      <div className="mb-4">
        <div className="flex items-center h-10 w-full hover:bg-gray-400 text-xl bg-gray-300 rounded-full px-2 py-1">
          <div>
          <img className="inline mr-4" src={icon_burbuja_naranja}></img>
          </div>
          <div>
          <h2 >  Cambios a esta política de privacidad:</h2>

          </div>
        </div>
        
        <p className="ml-8 my-8">
        Cratus se reserva el derecho de realizar cambios a esta política de privacidad en cualquier momento mediante notificación a sus Usuarios en la aplicación móvil. Se recomienda encarecidamente consultar esta página con frecuencia, refiriéndose a la fecha de la última modificación enumerada en la parte inferior. Si un Usuario se opone a cualquiera de los cambios en la Política, el Usuario debe dejar de usar las aplicaciones y el software de Cratus, y puede solicitar que Cratus elimine sus datos personales. A menos que se indique lo contrario, la política de privacidad vigente en ese momento se aplica a todos los datos personales que Cratus tiene acerca de los usuarios.
        </p>
      </div>
      <div className="mb-4">
        <div className="flex items-center h-10 w-full hover:bg-gray-400 text-xl bg-gray-300 rounded-full px-2 py-1">
          <div>
          <img className="inline mr-4" src={icon_burbuja_naranja}></img>
          </div>
          <div>
          <h2 >  Comunicación/darse de baja</h2>

          </div>
        </div>
        
        <p className="ml-8 my-8">
        Independientemente de la configuración que seleccione, Cratus puede enviarle comunicaciones relacionadas con el servicio para cancelar la suscripción y dejar de recibir mensajes, cambie su configuración o siga las instrucciones en el correo electrónico, mensaje de texto, notificación u otro tipo de mensaje. Si te has dado de baja de cualquier comunicación de Cratus, es posible que reciba comunicaciones que ya está dado de baja.
        </p>
      </div>
      <div className="mb-4">
        <div className="flex items-center h-10 w-full hover:bg-gray-400 text-xl bg-gray-300 rounded-full px-2 py-1">
          <div>
          <img className="inline mr-4" src={icon_burbuja_naranja}></img>
          </div>
          <div>
          <h2 > Usuarios no chilenos  </h2>

          </div>
        </div>
        
        <p className="ml-8 my-8">
        Independientemente del lugar donde viva, sin perjuicio de cualquier otro consentimiento y concesión otorgada por usted.
A continuación, también acepta que sus datos personales sean transferidos, procesados ​​y almacenados en el país utilizado por Cratus para el procesamiento de datos, y permite que Cratus use y recopile su información personal e información de acuerdo con esta Política de privacidad.
        </p>
      </div>
      <div className="mb-4">
        <div className="flex items-center h-10 w-full hover:bg-gray-400 text-xl bg-gray-300 rounded-full px-2 py-1">
          <div>
          <img className="inline mr-4" src={icon_burbuja_naranja}></img>
          </div>
          <div>
          <h2 > Condiciones de uso </h2>

          </div>
        </div>
        
        <p className="ml-8 my-8">
        Su uso de nuestros productos y servicios, y cualquier disputa que surja de ellos, está sujeto a esta Privacidad Política, así como nuestros Términos de uso. Visite nuestros Términos de uso, que explican otros términos. que rige el uso de nuestros productos y servicios.
        </p>
      </div>

      </div>
      {/* HomePage */}
      <Footer active="politicas"/>
    </div>
    )
  }

}
export default ConsultasHistoricasPage;