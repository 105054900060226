import React from 'react';
import Menu from '../Menu';
import Footer from '../Footer';
import Auth from './../../middleware/Auth';
// import woman from './../../assets/img/woman.jpg';
import {Link} from 'react-router-dom';
class RutinasPage extends React.Component{
  constructor(props){
    super(props)
    this.state={
      user: JSON.parse(sessionStorage.getItem('user')),
      rutinas: [],
      nombre: "",
      pasos:0,
      arr_pasos: [],
      pasos_view: [],
      open_r_form: false,
      paso_form: false,
      rutina_view: "",
      
    }
    this.handleChange= (e)=>this.setState({[e.target.name]: e.target.value})
    this.add_paso = ()=>{
      var paso = {step: this.state.arr_pasos.length, dispositivo: 1, color: "verde", toggle_select: false}
      var arr = this.state.arr_pasos
      arr.push(paso)
      this.setState({arr_pasos: arr})
    }
    this.changePasoColor = this.changePasoColor.bind(this)
    this.changePasodispositivo = this.changePasodispositivo.bind(this)
    this.changePasoToggleSelect = this.changePasoToggleSelect.bind(this)
    this.createRutina = this.createRutina.bind(this)
    this.removeLastStep = this.removeLastStep.bind(this)
    this.cerrar_formulario_rutina= ()=> this.setState({open_r_form: false, arr_pasos: [], nombre: "", pasos: 0})
    console.log("rutinas", this.state)
  }

  componentDidMount(){
    this.loadRutinas();
  }

  loadRutinas(){
    var url = "api/entrenadores/"+this.state.user.id+"/rutinas"
    fetch(url,{
      method: "GET",
      headers: {
        'access-token': Auth.getToken().access_token,
        'client': Auth.getToken().client,
        'uid': Auth.getToken().uid
      }
    })
    .then((res)=>{
      // console.log("loadrutinas",res)
      Auth.updateToken(res.headers.get('access-token'));
      return res.json()
    })
    .then((res)=>{
      // console.log("loadrutinas2", res)
      this.setState({rutinas: res.data})
    })
  }
  createRutina(){
    var url = "api/rutinas"
    var data = {
      user_id: this.state.user.id,
      nombre: this.state.nombre, 
      steps: this.state.arr_pasos.length,
      pasos_attributes: this.state.arr_pasos
    }
    fetch(url,{
      method: "POST",
      headers:{
        "Content-Type": "application/json",
        'access-token': Auth.getToken().access_token,
        'client': Auth.getToken().client,
        'uid': Auth.getToken().uid
      },
      body: JSON.stringify(data)
    }).then((res)=>{
      Auth.updateToken(res.headers.get('access-token'));
      return res.json()
    })
    .then((res)=>{
      console.log(res)
      if(res.status == "ok"){
        this.loadRutinas()
        this.cerrar_formulario_rutina()
      }
      // this.loadRutinas()
      // this.setState({})
    })
  }

  changePasodispositivo(paso, dispositivo){
    var pasos = this.state.arr_pasos
    var paso_index = pasos.indexOf(paso)
    var _paso = paso
    _paso.dispositivo = dispositivo
    pasos[paso_index] = _paso
    this.setState({arr_pasos: pasos})
  }

  changePasoColor(paso, color){
    var pasos = this.state.arr_pasos
    var paso_index = pasos.indexOf(paso)
    var _paso = paso
    _paso.color = color
    _paso.toggle_select = false
    pasos[paso_index] = _paso
    this.setState({arr_pasos: pasos})
  }
  changePasoToggleSelect(paso){
    var pasos = this.state.arr_pasos
    var paso_index = pasos.indexOf(paso)
    var _paso = paso
    _paso.toggle_select = !paso.toggle_select
    pasos[paso_index] = _paso
    this.setState({arr_pasos: pasos})
  }

  removeLastStep(){
    var arr = this.state.arr_pasos
    arr.pop()
    this.setState({arr_pasos: arr})
  }

  select_rutina(id){
    if(this.state.rutina_view == id){
      this.setState({rutina_view: ""})
      return
    }
    else{
      this.setState({rutina_view: id, pasos_view: []})
    }

    var url = "api/rutinas/"+id+"/pasos"
    fetch(url, {
      method: "GET",
      headers:{
        'access-token': Auth.getToken().access_token,
        'client': Auth.getToken().client,
        'uid': Auth.getToken().uid
      }
    }).then((res)=>{
      Auth.updateToken(res.headers.get('access-token'));
      return res.json()
    }).then((res)=>{
      console.log("res pasos rutina",res)
      this.setState({pasos_view: res.data})
    })
  }


  render(){
    console.log("render rutinas", this.state)
    return(
      <div className=" min-h-screen flex flex-col">
      <Menu active="rutinas"/>
      <div className="  page_content flex-grow flex flex-col p-6">
        <div className="font-bold text-4xl text-rojo">
        RUTINAS
        </div>
        {
          this.state.rutinas.map((rutina)=>
          {
            return(
              <>
              <div
              onClick={()=>this.select_rutina(rutina.id)}
              className="rounded-lg cursor-pointer flex  my-2 italic w-full justify-between px-4 py-2 bg-rojo hover:bg-red-600 hover:shadow-lg text-white text-2xl">
              <div> {rutina.nombre} </div>
              <div> {rutina.steps+" PASOS"} </div>
            </div>
            {this.state.rutina_view == rutina.id?
            <div className="flex justify-start flex-wrap" >
                          {
              this.state.pasos_view.map((paso,i)=>{
                return(
                  <div key={paso.step} className={"relative rounded-lg flex w-20 my-2 mx-2 italic justify-between px-4 py-2 hover:shadow-lg text-black text-2xl bg-"+paso.color}>
                    {paso.dispositivo}
                  </div>
                )
              })
            }
            </div>
            :
            null}
            </>
            )
          })
        }


        {
          this.state.open_r_form?
          <div className="w-full flex flex-col">
            
            <div className="flex rounded-lg my-2 italic w-full justify-between px-4 py-2 bg-rojo hover:bg-red-600 hover:shadow-lg text-white text-2xl">
              <input className=" rounded-sm text-black opacity-50" placeholder="Nombre rutina" value={this.state.nombre} onChange={this.handleChange} name="nombre"></input>              
              <div className="flex">
              <div className="px-2"> {this.state.arr_pasos.length+" PASOS"} </div>

              <button className="px-2 mx-2 bg-black" onClick={this.createRutina}>GUARDAR</button>
              <button onClick={this.cerrar_formulario_rutina} className="px-2 mx-2 bg-black">X</button>
            </div>
            </div>
            <div className="flex justify-start flex-wrap">
            {
              this.state.arr_pasos.map((paso,i)=>{
                return(
                  <div key={paso.step} className={"relative rounded-lg flex w-20 my-2 mx-2 italic justify-between px-4 py-2 hover:shadow-lg text-black text-2xl bg-"+paso.color}>
                    <select className={"z-1 bg-"+paso.color} value={paso.dispositivo} onChange={(e)=>this.changePasodispositivo(paso, e.target.value)}>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                 
                        <button className={"absolute top-0 right-0 w-5 h-5 border-solid border-2 border-black cursor-pointer bg-"+paso.color} onClick={()=>this.changePasoToggleSelect(paso)}> </button>
                      {
                        paso.toggle_select?                        
                        <ul className="z-50 absolute top-0 right-0 border-solid border-2 border-black">

                          <li className="cursor-pointer w-5 h-5 bg-verde" value="verde"
                          onClick={(e)=>this.changePasoColor(paso, "verde")}
                          ></li>
                          <li className="cursor-pointer w-5 h-5 bg-amarillo" value="amarillo"
                          onClick={(e)=>this.changePasoColor(paso, "amarillo")}                      
                          ></li>
                          <li className="cursor-pointer w-5 h-5 bg-rojo" value="rojo"
                          onClick={(e)=>this.changePasoColor(paso, "rojo")}                      
                          ></li>
                          <li className="cursor-pointer w-5 h-5 bg-azul" value="azul"
                          onClick={(e)=>this.changePasoColor(paso, "azul")}                      
                          ></li>
                          <li className="cursor-pointer w-5 h-5 bg-morado" value="morado"
                          onClick={(e)=>this.changePasoColor(paso, "morado")}                      
                          ></li>
                        </ul>
                        :
                        null
                      }
                      {
                        i + 1 == this.state.arr_pasos.length ?
                        <div 
                        onClick={this.removeLastStep}
                        className="cursor-pointer absolute bottom-0 right-0 w-5 h-5 text-small mb-2 ">
                        x
                        </div> 
                        :
                        null
                      }

                  </div>
                )
              })
            }
            <div onClick={this.add_paso} className="cursor-pointer flex my-2 rounded-lg  w-20 mx-2 italic justify-center px-4 py-2 bg-negro-icono-cratus hover:shadow-lg text-white text-2xl">
              <div> + </div>
            </div> 
            </div>  


          </div> 
          : 
              <div onClick={()=>this.setState({open_r_form: !this.state.open_r_form})} className=" cursor-pointer rounded-lg flex my-2  italic w-full justify-center px-4 py-2 bg-negro-icono-cratus hover:shadow-lg text-white text-2xl">
                <div> + </div>
              </div>  

        }
      </div>
      <Footer/>
    </div>
    )
  }

}
export default RutinasPage;