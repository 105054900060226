import React from 'react'
import Menu from './../Menu';
import Footer from './../Footer';
import bg_cratus from './../../assets/img/bg_cratus.jpg';
import {Link} from 'react-router-dom';

class HomePage extends React.Component{
  constructor(props){
    super(props)
  }
  render(){
    return( 
    <div className="min-h-screen flex flex-col">
      <Menu active="home"/>
      <div className="flex-grow  flex " style={{backgroundImage: `url(${bg_cratus})`, backgroundSize: "cover", backgroundPosition: "center"}}>
        <div className="w-1/3 my-auto page_content">
          <ul>
            <li>
            <Link to="/register">
              <button className="text-3xl hover:shadow-lg my-4 mx-20 p-2 bg-negro-icono-cratus border-rojo border-solid border-4 px-5 rounded-full text-white" style={{width: "20rem"}}>  
                REGISTRO
              </button>
              </Link>
            </li>
            <li>
            <Link to="/login">
              <button className="text-3xl hover:shadow-lg my-4 mx-20 p-2 bg-negro-icono-cratus border-rojo border-solid border-4 px-5 rounded-full text-white" style={{width: "20rem"}}>  
                ACCESO
              </button>
              </Link>
            </li>
            {/* <li>
            <Link to="/dudas">
              <button className="text-3xl hover:shadow-lg my-4 mx-20 p-2 bg-negro-icono-cratus border-rojo border-solid border-4 px-5 rounded-full text-white" style={{width: "20rem"}}>  
                DUDAS
              </button>
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
      {/* HomePage */}
      <Footer/>
    </div>
    )
  }
}

export default HomePage;