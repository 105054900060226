import React from 'react';
import {Link, withRouter} from 'react-router-dom';

import icono_cratus from './../../assets/img/icono_cratus.png';
import Auth from './../../middleware/Auth';
import facebook from './../../assets/img/facebook.png';
import youtube from './../../assets/img/youtube.png';
import instagram from './../../assets/img/instagram.png';

import SweetAlert from 'react-bootstrap-sweetalert';




class ChangePassword extends React.PureComponent {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(props.location.search);
    this.state = {
      password: "",
      password_confirmation: "",
      reset_password_token: params.get('token') ? params.get('token') : null,
      client: params.get('client') ? params.get('client') : null,
      expiry: params.get('expiry') ? params.get('expiry') : null,
      uid: params.get('uid') ? params.get('uid') : null,
      alerta: null,
    };
    this.handleChange = (e) => this.setState({[e.target.name]: e.target.value});
    this.setAlert = (alerta) => this.setState({alerta: alerta})
    this.hideAlert = () => this.setState({alerta: null});
    this.enviar = this.enviar.bind(this);
  }

  enviar() {
    this.setAlert(
      <SweetAlert title="Cargando" showConfirm={false} showCancel={false} onConfirm={()=>console.log("loading")}>
        <i className="fas fa-spinner fa-5x rotate" style={{color: "#66BD51"}}></i>
      </SweetAlert>
    )
    const data = {
      password: this.state.password,
      password_confirmation: this.state.password_confirmation
    }

    var url ='/api/auth/password';

    fetch(url, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        "access-token": this.state.reset_password_token,
        "client":this.state.client,
        "client_id":this.state.client_id,
        "expiry": this.state.expiry,
        "uid": this.state.uid
      }
    }).then((res) => {
      return res.json();
    }).then((res) => {

      if (res.success){
        this.setAlert(
          <SweetAlert success title="Ok" showConfirm={false} showCancel={false} onConfirm={this.hideAlert}>
              Se ha cambiado la contraseña satisfactoriamente
              <br/>
              <Link to={{pathname: "/login"}} style={{color : "#66bd51", fontSize: "1rem" }}> <button>Volver a Login</button> </Link>
          </SweetAlert>
        )
      }else{
        this.setAlert(
          <SweetAlert danger title="Ups" onConfirm={this.hideAlert}>
            {"A ocurrido un error durante a solicitud..."}
          </SweetAlert>
        )
      }


    }).catch((err) => console.log("error login: ", err))
    
  }

  render() {
    return( 
        <div className="w-screen h-screen flex bg-degradado">
          {this.state.alerta}
        <div className="  page_content m-2 sm:m-16 w-full flex shadow-lg">
          <div className="w-full sm:w-2/3 flex flex-col p-5 lg:p-12 bg-white relative ">
            <div className="flex flex-col justify-around h-full">
            <div>
            <h1 className="text-center  text-rojo text-4xl font-bold">Nueva Contraseña</h1>
            </div>
            <div className="mx-8">
            <label className="font-bold text-2xl">Nueva Clave</label>
            <input onChange={this.handleChange} value={this.state.password} name="password"
            className=" border-solid border-rojo border-b-2 w-full focus:bg-gray-300" type="password" placeholder="***********"></input>         
            </div>
            <div className="mx-8">
            <label className="font-bold text-2xl">Confirmar Clave</label>
            <input 
            onChange={this.handleChange} value={this.state.password_confirmation} name="password_confirmation"
            className=" border-solid border-rojo border-b-2 w-full focus:bg-gray-300" type="password" placeholder="***********"></input> 
            
            </div>
    
            <div className="text-center w-full">
            <button className="text-white px-8 font-bold py-1 m-2 rounded-full text-xl bg-rojo hover:shadow-lg" onClick={this.enviar}>
            CAMBIAR CONTRASEÑA
            </button> <br/>
            </div>
            {/* <span className="text-xl ml-10 mb-5 text-lg lg:absolute lg:bottom-0 lg:left-0">¿No Tienes una Cuenta? 
            <Link  className="px-3 font-bold text-rojo" to="/register">REGISTRAR</Link>
            </span>
            <span className="text-xl mr-10 mb-5  text-lg lg:absolute lg:bottom-0 lg:right-0">Volver a la página  
            <Link  className="px-3 font-bold text-rojo" to="/home">INICIO</Link>
    
            </span> */}
            </div>
            <div>
            <span className="ml-10 mb-4 hidden sm:inline text-sm sm:absolute sm:bottom-0 sm:left-0">
            ¿No tienes una Cuenta? 
            <Link  className="px-3 font-bold text-rojo" to="/register">REGISTRAR</Link>
            </span>
            <span className="mr-10 mb-4 hidden sm:inline text-sm sm:absolute sm:bottom-0 sm:right-0">
            Volver a la página  
            <Link  className="px-3 font-bold text-rojo" to="/home">INICIO</Link>
            </span>
            {/* celulares */}
            <Link  className="ml-10 mb-4 absolute bottom-0 left-0 sm:hidden px-3 font-bold text-rojo" to="/login">LOGIN</Link>
            <Link  className="mr-10 mb-4 absolute bottom-0 right-0 sm:hidden px-3 font-bold text-rojo" to="/home">INICIO</Link>
    
            </div>
          </div>
          <div className=" w-0 invisible sm:visible sm:w-1/3 bg-negro-icono-cratus flex relative">
            <div className="absolute top-0 right-0 flex">
    
            <div className="m-3 w-8 h-8">
                <a href="https://www.facebook.com/Cratus.chile">
                  <img  src={facebook} alt="icono_cratus"></img>
                </a>
              </div>
    
              <div className="m-3 w-8 h-8">
                <a href="https://www.instagram.com/cratus_sport/">
                  <img  src={instagram} alt="icono_cratus"></img>
                </a>
              </div>
    
              <div className="m-3 w-8 h-8">
                <a href="youtube.com/channel/UCeMcc57l-wrs0DwD3Dhva2A/featured?view_as=subscriber">
                  <img  src={youtube} alt="icono_cratus"></img>
                </a>
              </div>
    
            </div>
            <div className="m-auto">
              <img src={icono_cratus} alt="logo-conversemos"></img>
            </div>
            {/* <div className="mb-4 absolute bottom-0 w-full text-center">
              <span className="lg:text-sm  text-gray-500">UN PRODUCTO DE</span>
              <h1 className="lg:text-2xl font-bold text-gray-500">IDEAS MACHINE</h1>
            </div> */}
    
          </div>
        </div>
        </div>
         )
  }
}

export default withRouter(ChangePassword);


