import React from 'react'

class Auth extends React.Component{

	static authenticateToken(client,access_token,uid){
		sessionStorage.setItem('client', client);
		sessionStorage.setItem('access_token', access_token);
		sessionStorage.setItem('uid', uid);

		console.log("sesion actual: ", sessionStorage.getItem('client'),
			sessionStorage.getItem('access_token'), sessionStorage.getItem('uid'));
	}

	static updateToken(access_token){
		if (access_token != "" && access_token != null) {
			sessionStorage.setItem('access_token', access_token);
			console.log("token actualizado: ", sessionStorage.getItem('access_token'), access_token);
		}
		else {
			console.log("es vacio, se mantiene: ", sessionStorage.getItem('access_token'));
		}

	}

	static isUserAuthenticate(){
		if(sessionStorage.getItem('client') == null)	return false
		if(sessionStorage.getItem('access_token') == null)	return false
		if(sessionStorage.getItem('uid') == null)	return false

		return true
	}

	static deauthenticateUser(){
		sessionStorage.removeItem('client');
		sessionStorage.removeItem('access_token');
		sessionStorage.removeItem('uid');
	}

	static getToken(){
		return sessionStorage;
	}
}

export default Auth;
